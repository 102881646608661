import { TiendaProductosData } from "src/app/components/tienda/productos/productosData";
import { Deserializable } from "../deserializable";
import { CategoriasJerarquicasListado } from "src/app/components/mestres/categorias-jerarquias";

export class CategoriasJerarquicasToShow implements Deserializable, CategoriasJerarquicasListado {
    pkId: number
    nombre: string
    nivel: number
    padreId?: number
    padreNombre?: string
    orden?: number
    tipoElemento: number
    centrosIds?: string
    centrosNombres?: string
    categorias: any[]
    
	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

	assign(param,data){
		this[param] = data
	}
	
}