import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	GrupoClientes,
	grupos_cliente_Respuesta,
} from "@sharedV11/classes/clients/client/grupos-cliente-Respuesta.model";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { EditClientGroupService } from "@sharedV11/services/components/edit_client_group/edit-client-group.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
	DatosLista,
	DatosQueryParams,
	CategoriasJerarquicasListado,
	CategoriaJerarquiaFormData,
	CategoriaJerarquiaRequest,
} from "../model/model";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";

@Component({
	selector: "app-edit-categorias-jerarquias",
	templateUrl: "./edit-categorias-jerarquias.html",
	styleUrls: ["./edit-categorias-jerarquias.scss"],
})
export class EditCategoriasJerarquias implements OnInit {
	public loading: boolean = true;
	private ngUnsubscribe = new Subject();

	@Input() pkId: string;
	@Input() mode: string;
	@Input() modificar: boolean;

	translation: any;
	editGroupModelForm: FormGroup;

	categoriajerarquia: CategoriasJerarquicasListado;
	// datos lista
	padres: DatosLista[] = [];

	// Tipo Elemento
	tipoElemento = [
		{
			name: "Tienda",
			value: 2,
		},
		{
			name: "Restauración",
			value: 3,
		},
	];

	//centros
	centrosSinAsignar = [];
	centrosAsignados = [];
	tipoCentros: any[] = [];
	//centros
	format = {
		add: "Añadir",
		remove: "Borrar",
		all: "Todos",
		none: "Ninguno",
		direction: DualListComponent.LTR,
		draggable: true,
		locale: "es",
	};

	centrosCelectados: CategoriasJerarquicasListado;
	centrosSinCeleccionados: CategoriasJerarquicasListado[] = [];

	constructor(
		private snackvar_service: SnackbarInfoService,
		private editClientService: EditClientGroupService,
		private panelService: PanelService,
		private fb: FormBuilder,
		private translator: Translator,
		private httpErrorService: HttpErrorsService,
		private gsCentros: GlobalServicCentros,
		private conectarApiService: ConectarApiService
	) {}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	ngOnInit(): void {
		this.loadCentros();
		this.conectarApiService
			.getDatosListas()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((data) => {
				this.padres = data.datosResult.datosListas;

				if (this.pkId) {
					this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
					this.loading = false;
				}
			});

		this.initializeTranslate();
		this.initializeFormWithoutData();

		this.manageForm(this.pkId, this.mode);
	}

	async loadCentros() {
		await this.gsCentros.getCentros();
	}

	async initializeTranslate() {
		this.translation = this.translator.GetTranslations();
		this.format = {
			add: this.translation.Anadir,
			remove: this.translation.Borrar,
			all: this.translation.Todos,
			none: this.translation.Ninguno,
			direction: DualListComponent.LTR,
			draggable: true,
			locale: "es",
		};
	}

	tipoElementChang(tipoElemento): void {
		this.loading = true;

		const queryParams: DatosQueryParams = {
			NombreCampo: "TipoElemento",
			FiltroCampo: {
				value: tipoElemento.value.value,
				name: tipoElemento.value.name,
			},
		};

		this.conectarApiService
			.getDatosListas(queryParams)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(data) => {
					this.padres = data ? data.datosResult.datosListas : [];

					this.loading = false;
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}

	findCategoria(pkId) {
		this.conectarApiService
			.getCategoriasJerarquicas(null, [
				{
					db: null,
					filterApi: "pkId",
					name: "pkId",
					show: "pkId",
					translation: "pkId",
					value: pkId,
				},
			])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(data) => {
					if (data) {
						this.categoriajerarquia = data?.datosResult?.listado[0];
						this.changeValueForm(data?.datosResult?.listado[0]);

						this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
						this.centrosAsignados = this.gsCentros.daTiposCentros(
							data?.datosResult?.listado[0]
						);

						this.centrosCelectados = data.datosResult.listado[0];
					}
					this.valuedChanged();
					this.loading = false;
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}

	initializeFormWithoutData() {
		this.editGroupModelForm = this.fb.group({
			pkId: [""],
			NombreGruposClientes: [
				"",
				[Validators.required, Validators.pattern(/[A-Za-z]/)],
			],
			TipoElemento: [null, Validators.required],
			Nivel: [null, [Validators.required, Validators.max(1000)]],
			Padre: [
				{
					pkid: null,
					nombre: null,
					tipoElemento: null,
				},
			],
			Orden: [null, Validators.max(1000)],
			CentrosIds: [""],
		});
	}

	changeValueForm(data: CategoriasJerarquicasListado) {
		if (!data) {
			this.editGroupModelForm.reset();
			this.panelService.setDiscardChanges(true);
			return;
		}

		this.formControls["pkId"].setValue(data.pkId);
		this.formControls["NombreGruposClientes"].setValue(data.nombre);

		const findTipoElemento = this.tipoElemento.find(
			(tipo) => tipo.value === data.tipoElemento
		);
		this.formControls["TipoElemento"].setValue(findTipoElemento);

		this.formControls["Nivel"].setValue(data.nivel);
		this.formControls["Padre"].setValue({
			pkid: data.padreId,
			nombre: data.padreNombre,
			tipoElemento: data.tipoElemento,
		});
		this.formControls["Orden"].setValue(data.orden);

		this.formControls["CentrosIds"].setValue(data.centrosIds);

		if (data.tipoElemento) {
			this.tipoElementChang({
				value: {
					pkid: data.padreId,
					name: data.padreNombre,
					value: data.tipoElemento,
				},
			});
		}

		this.panelService.setDiscardChanges(true);
	}

	compareCategoryObjectsFn(object1: DatosLista, object2: DatosLista) {
		return object1 && object2 && object1.pkid == object2.pkid;
	}

	manageForm(pkId, mode) {
		if (mode == "I") {
			this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
			this.centrosSinCeleccionados = this.centrosSinAsignar;
			this.loading = false;
			this.valuedChanged();
		}
		if (pkId != null) {
			this.findCategoria(pkId);
		}
	}

	get formControls() {
		return this.editGroupModelForm.controls;
	}

	submit() {
		let grupoClientes: CategoriaJerarquiaFormData =
			this.editGroupModelForm.value;
		this.editGroupModelForm.markAllAsTouched();

		if (this.editGroupModelForm.valid) {
			this.manageApi(grupoClientes);
		}
	}

	destroy() {
		this.panelService.setDiscardChanges(true);
		this.panelService.setClosePanel(null);
	}
	save() {
		this.loading = false;
		this.panelService.setSavePanel(null);
	}
	discard() {
		this.changeValueForm(this.categoriajerarquia);
		this.editGroupModelForm.markAllAsTouched();

		switch (this.mode) {
			case "I":
				this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
				this.centrosAsignados = [];
				break;
			case "U":
				if (this.centrosCelectados) {
					this.centrosAsignados = this.gsCentros.daTiposCentros(
						this.centrosCelectados
					);
				}
				break;
			case "D":
				if (this.centrosCelectados) {
					this.centrosAsignados = this.gsCentros.daTiposCentros(
						this.centrosCelectados
					);
				}
				break;

			default:
				break;
		}
	}

	manageApi(formData: CategoriaJerarquiaFormData) {
		formData.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
			this.centrosAsignados
		);
		this.loading = true;

		const bodyRequest: CategoriaJerarquiaRequest = {
			nombre: formData.NombreGruposClientes,
			tipoElemento: +formData.TipoElemento.value,
			nivel: formData.Nivel,
			padreId: formData?.Padre?.pkid || null,
			orden: formData.Orden,
			centrosIds: formData.CentrosIds || "",
		};

		switch (this.mode) {
			case "I":
				this.InsCategoriaJerarquia(bodyRequest);
				//insert
				break;
			case "U":
				//update
				bodyRequest.pkId = +this.pkId;
				bodyRequest.padreNombre = formData?.Padre?.nombre || null;

				this.UpdCategoriaJerarquia(bodyRequest);
				break;
			case "D":
				//duplicate
				this.editGroupModelForm.value.pkId = "0";
				this.InsCategoriaJerarquia(bodyRequest);
				break;

			default:
				break;
		}
	}

	UpdCategoriaJerarquia(bodyRequest: CategoriaJerarquiaRequest) {
		this.conectarApiService
			.putCategoriasJerarquicas(bodyRequest)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(response) => {
					this.nextWindow(response);
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}

	InsCategoriaJerarquia(bodyRequest: CategoriaJerarquiaRequest) {
		this.conectarApiService
			.postCategoriasJerarquicas(bodyRequest)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(response) => {
					this.nextWindow(response);
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}

	nextWindow(response: any) {
    
    this.save();
    
		const successMessageKey = {
			I: "CreatedSuccessfully",
			U: "UpdatedSuccessfully",
			D: "DuplicatedSuccessfully",
		};

		if (response?.mensajes?.length > 0) {
			response.mensajes.forEach((element) => {
				this.snackvar_service.openSnackBar(
					this.translation["categoriajeraquica"]["errorResponse"][
						element?.codigoMensaje
					],
					"",
					"red-snackbar"
				);
			});
		} else {
			this.snackvar_service.openSnackBar(
				this.translation[successMessageKey[this.mode]],
				"",
				"green-snackbar"
			);
      
		  this.destroy();
		}
	}

	notEmptyFields(grupo_cl: GrupoClientes): boolean {
		let res = true;
		if (
			grupo_cl.NombreGruposClientes.length === 0 ||
			grupo_cl.CodigoGruposClientes.length === 0
		) {
			res = false;
		}
		return res;
	}

	valuedChanged() {
		this.editGroupModelForm.valueChanges.subscribe((value) => {
			if (this.padres != null) {
				this.panelService.setDiscardChanges(false);
				if (JSON.stringify(value) === JSON.stringify(this.categoriajerarquia)) {
					this.panelService.setDiscardChanges(true);
				}
			}
		});
	}

	findClientGroup() {
		this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
		this.loading = false;
	}

	validateFields(field: string) {
		return this.isValidAnyField(field);
	}

	isValidAnyField(field: string) {
		let fc_pv = this.formControls;
		return fc_pv[field].errors != null ? true : false;
	}

	getErrorMessage(field: string) {
		let fc_pv = this.editGroupModelForm.get(field);
		const errorsText = {
			required: this.translation["CampoRequerido"],
			NombreGruposClientes: fc_pv.hasError("pattern")
				? this.translation["SoloLetras"]
				: "Error",
			Nivel: fc_pv.hasError("max") ? this.translation["SoloPorMil"] : "Error",
			Orden: fc_pv.hasError("max") ? this.translation["SoloPorMil"] : "Error",
		};

		return fc_pv.errors.required ? errorsText.required : errorsText[field];
	}

	existErrors() {
		return this.formControls.CodigoGruposClientes.errors ||
			this.formControls.NombreGruposClientes.errors
			? true
			: false;
	}

	isMovedCentro(): void {
		this.panelService.setDiscardChanges(false);
	}
}
