import { Injectable } from "@angular/core";
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import { SnackbarInfoService } from "../alerts/snackbar-info/snackbar-info.service";

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(
    private snackvar_service: SnackbarInfoService,
  ) { }

  parseCSV<T>(file: File): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result as string;
        Papa.parse(csvData, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const data = this.processData<T>(result.data);
            resolve(data);
          },
          error: (error) => {
            console.error('Error al procesar el archivo CSV:', error);
          },
        });
      };

      reader.onerror = (error) => {
        console.error('Error al leer el archivo CSV:', error);
        reject(error);
      };

      reader.readAsText(file);
    });
    
  }

  parseXLSX<T>(file: File): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Leer la primera hoja
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        const data_ = this.processData<T>(sheetData);
        resolve(data_);
      };
      reader.onerror = (error) => {
        console.error('Error al procesar el archivo XLSX:', error);
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  processData<T>(data: any[]): T[] {
    if (data.length > 0) {
      console.log('Datos:', data);
      return [
        ...data
      ]
    } else {
      console.error('No se encontraron datos en el archivo');
      return [];
    }
  }


  async parseFile<T>(file: File): Promise<T[]> {

    try {
      const extension = file.name.split('.').pop();
      let data: T[] = [];
      if (extension === 'csv') {
        data = await this.parseCSV(file);
      } else if (extension === 'xlsx') {
        data = await this.parseXLSX(file);
      } else {
        this.snackvar_service.openSnackBar(
          "",
          "",
          "red-snackbar",
          "ErrorArchivoExcel"
        )
      }
      return data;
    } catch (error) {
      console.error('Error al procesar el archivo:', error);
      return [];
    }
  }

}