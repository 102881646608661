import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { grupos_cliente_Respuesta } from "@sharedV11/classes/clients/client/grupos-cliente-Respuesta.model";
import { usuario_Respuesta } from "@sharedV11/classes/clients/client/usuario-Respuesta.model";
import { Paginator } from "@sharedV11/classes/paginator/paginator";
import { formas_pago_Respuesta } from "@sharedV11/classes/userRequest/clientes/formas-pago-Respuesta.model";
import { user_types_response } from "@sharedV11/classes/userResponse/userTypeResponse/user_types_response";
import { Usuario_response } from "@sharedV11/classes/userResponse/usuarioResponse/usuario_response";
import { DialogoPopUp } from "@sharedV11/components/alert/dialog/popup.service";
import { DivisaObject } from "@sharedV11/interfaces/DivisaObject.interface";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { EditCategoriasService } from "@sharedV11/services/components/Categorias/edit-categorias.service";
import { ClientService } from "@sharedV11/services/components/client/client.service";
import { CodigoPromocionalBinesService } from "@sharedV11/services/components/codigo-promocional-bines/codigo-promocional-bines.service";
import { EditTipoCodigoPromocionesService } from "@sharedV11/services/components/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.service";
import { EditClientGroupService } from "@sharedV11/services/components/edit_client_group/edit-client-group.service";
import { GrupoTipoBonoV11Service } from "@sharedV11/services/components/entradas-y-promociones/grupo-tipo-bono-v11/grupo-tipo-bono-v11.service";
import { ItemsMotivosGruposV11Service } from "@sharedV11/services/components/entradas-y-promociones/items-motivos-grupos-v11/items-motivos-grupos-v11.service";
import { BonoTypeService } from "@sharedV11/services/components/entradas-y-promociones/tipobono/bono-type.service";
import { EditDiasdeCierreService } from "@sharedV11/services/components/festivos/festivos";
import { EditGrupoRecintosService } from "@sharedV11/services/components/grupo-recinto/edit-grupo-recintos.service";
import { GruposActividadTallerService } from "@sharedV11/services/components/grupos-actividad-taller/grupos-actividad-taller.service";
import { RecintosVisitaService } from "@sharedV11/services/components/recintos/recintos-visita/recintos-visita.service";
import { TiendaProductosService } from "@sharedV11/services/components/tienda/productos.service";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import moment from "moment";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { EditClientGroupComponent } from "src/app/components/clients/client-group/edit-client-group/edit-client-group.component";
import { EditClientTypeComponent } from "src/app/components/clients/client-type/edit-client-type/edit-client-type.component";
import { EditClientComponent } from "src/app/components/clients/client-v11/edit-client/edit-client.component";
import { EditFormasPagoV11Component } from "src/app/components/clients/formas-pago-v11/edit-formas-pago-v11/edit-formas-pago-v11.component";
import { EditCodigoPromocionalBinesComponent } from "src/app/components/codigos-promocionales/codigo-promocional-bines/edit-codigo-promocional-bines/edit-codigo-promocional-bines.component";
import { EditCodigoPromocionalComponent } from "src/app/components/codigos-promocionales/codigo-promocional/edit-codigo-promocional/edit-codigo-promocional.component";
import { EditTipoCodigoPromocionesComponent } from "src/app/components/codigos-promocionales/tipo-codigo-promociones/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.component";
import { EditEntryTypeComponent } from "src/app/components/entradaspromociones/entry-type/edit-entry-type/edit-entry-type.component";
import { EditGrupoTipoBonoV11Component } from "src/app/components/entradaspromociones/grupo-tipo-bono-v11/edit-grupo-tipo-bono-v11/edit-grupo-tipo-bono-v11.component";
import { EditGruposTipoEntradaV11Component } from "src/app/components/entradaspromociones/grupos-tipo-entrada-v11/edit-grupos-tipo-entrada-v11/edit-grupos-tipo-entrada-v11.component";
import { EditItemsMotivosGruposV11Component } from "src/app/components/entradaspromociones/items-motivos-grupos-v11/edit-items-motivos-grupos-v11/edit-items-motivos-grupos-v11.component";
import { EditReglasDeNegocioComponent } from "src/app/components/entradaspromociones/reglas-de-negocio/edit-reglas-de-negocio/edit-reglas-de-negocio.component";
import { EditTipoBonoComponent } from "src/app/components/entradaspromociones/tipobono/edit-tipobono/edit-tipobono.component";
import { EditEspectaculosComponent } from "src/app/components/espacios/espectaculos/edit-espectaculos/edit-espectaculos.component";
import { EditSesionesConButacasComponent } from "src/app/components/espacios/sesionesconbutacas/edit-sesionesconbutacas/edit-sesionesconbutacas.component";
import { EditCategoriasJerarquias } from "src/app/components/mestres/categorias-jerarquias/edit-categorias-jerarquias/edit-categorias-jerarquias";
import { EditCategoriasComponent } from "src/app/components/mestres/categorias/edit-categorias/edit-categorias.component";
import { EditDiascierreComponent } from "src/app/components/mestres/diascierre/edit-diascierre/edit-diascierre.component";
import { EditTpvsComponent } from "src/app/components/mestres/tpvs/edit-tpvs/edit-tpvs.component";
import { EditAccessControlTypeComponent } from "src/app/components/mestres/type-access-control/edit-type-access-control/edit-type-access-control.component";
import { EditGrupoRecintosComponent } from "src/app/components/recintos/grupo-recintos/edit-grupo-recintos/edit-grupo-recintos.component";
import { EditGruposActividadTallerV11Component } from "src/app/components/recintos/grupos-actividad-taller-v11/edit-grupos-actividad-taller-v11/edit-grupos-actividad-taller-v11.component";
import { EditRecintosVisitaComponent } from "src/app/components/recintos/recintos-visita/edit-recintos-visita/edit-recintos-visita.component";
import { EditSinButacasComponent } from "src/app/components/recintos/sinbutacas/edit-sinbutacas/edit-sinbutacas.component";
import { EditSessionTypeComponent } from "src/app/components/recintos/tipossesion/edit-sessiontype/edit-sessiontype.component";
import { EditGruposTarifasComponent } from "src/app/components/tarifas/grupos-tarifas/edit-grupos-tarifas/edit-grupos-tarifas.component";
import { EditGroupComponent } from "src/app/components/tarifas/internet-groups/edit-group/edit-group.component";
import { EditTarifaComponent } from "src/app/components/tarifas/tarifa-v11/edit-tarifa/edit-tarifa.component";
import { EditTiposreservaComponent } from "src/app/components/tarifas/tiposreserva/edit-tiposreserva/edit-tiposreserva.component";
import { EditProductoComponent } from "src/app/components/tienda/productos/edit-producto/edit-producto.component";
import { ProfileSaveComponent } from "src/app/components/user/user-profile-v11/profile-save/profile-save.component";
import { EditUserComponent } from "src/app/components/user/user-v11/edit-user/edit-user.component";
import { table } from "src/app/shared/classes/tables/table";
import { FilterService } from "src/app/shared/services/filter/filter.service";
import { Translator } from "src/app/shared/services/translator/translator.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit, AfterViewInit {
  private ngUnsubscribe = new Subject();
  @Input() tableName: string;
  //@Input() permisobtnEliminar:boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() parent_pkId: string;
  @Input() subparent_pkId: string;
  @Output() addbut = new EventEmitter<boolean>();

  @Output() totales = new EventEmitter<any>();

  public dataSource: MatTableDataSource<any>;
  public columnsToDisplay: string[] = [];

  b_checkParams = false;

  public translation: any;
  public table: table = {
    tableName: null,
    maxItems: 10,
    filterByApi: false,
    columns: null,
  };
  public showTable = false;
  // Panel
  public customanpanel: any;
  public customanPkId: any;
  public customanMode: any;
  public paginatorLength = 0;
  public paginatorSize = 0;
  public tableLoaded = false;
  public panelLoaded = false;

  //permisos
  permisoFormulario: boolean = true; //
  permisobtnNuevo: boolean = false;
  permisobtnImportar: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  selectedRowIndex: any;
  NumeroDecimales: number;

  constructor(
    private translator: Translator,
    private tokenService: ApiTokenService,
    private editClientService: EditClientGroupService,
    private filterService: FilterService,
    public cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    public dialog: MatDialog,
    public servicioAPI: ConectarApiService,
    private alertaService: AlertService,
    private clientService: ClientService,
    public snackvar_service: SnackbarInfoService,
    private imgroupService: ItemsMotivosGruposV11Service,
    private grupoTipoBonoService: GrupoTipoBonoV11Service,
    private grupoRecintosService: EditGrupoRecintosService,
    private recintosVisitaService: RecintosVisitaService,
    private gruposActividadTallerService: GruposActividadTallerService,
    private categoriasService: EditCategoriasService,
    private diasdeCierreService: EditDiasdeCierreService,
    private TiposCodigoPromocionesService: EditTipoCodigoPromocionesService,
    private bonoTypeBono: BonoTypeService,
    public httpErrorService: HttpErrorsService,
    private route: ActivatedRoute,
    private router: Router,
    private codigoPromocionalBinesService: CodigoPromocionalBinesService,
    private conectarApiService: ConectarApiService,
    private tiendaProductoService: TiendaProductosService
  ) {
  }

  ngOnDestroy() {
    this.filterService.setTable({
      tableName: null,
      maxItems: 10,
      columns: null,
    });
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    /* this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator; */

    this.paginatorManage();
    this.sortManage();
    this.getData();
    this.filterService.tableLoaded.subscribe((ev) => {
      this.tableLoaded = ev;
    });

    if (this.sort) {
      this.sort.sortables.forEach((value, key) => {
        //this.sort.deregister(value)
      });
    }
  }
  ngOnInit(): void {
    this.NumeroDecimales = 4;
    //if (permisos)
    let ruta = "/";
    this.route.snapshot.pathFromRoot[
      this.route.snapshot.pathFromRoot.length - 1
    ].url.forEach((elemento) => {
      ruta = ruta + elemento.path + "/";
    });
    ruta = ruta.substring(0, ruta.length - 1);

    this.servicioAPI
      .getPermisosVentana(
        ruta,
        this.tokenService.getConfig("CODIGO_APLICACION"),
        this.tokenService.getConfig("ID_APLICACION")
      )
      .subscribe((dr) => {
        if (environment['VALIDATE_PERMISSIONS']) {
          if (dr.Mensajes.length == 0) {
            let permisos = dr.DatosResult.Permisos;
            this.permisoFormulario = this.visualizacionAccionAPI(
              permisos,
              ruta,
              "VER"
            );
            if (!this.permisoFormulario) {
              this.router.navigate(["/portada"]);
            }
            this.permisobtnNuevo = this.visualizacionAccionAPI(
              permisos,
              ruta,
              "NUEVO"
            );

            this.permisobtnImportar = this.permisobtnNuevo;

            if (this.permisobtnNuevo == false) this.addbut.emit(false);
            this.permisobtnGuardar = this.visualizacionAccionAPI(
              permisos,
              ruta,
              "MODIFICAR"
            );
            this.permisobtnEliminar = this.visualizacionAccionAPI(
              permisos,
              ruta,
              "ELIMINAR"
            );
            this.permisobtnDuplicar = this.visualizacionAccionAPI(
              permisos,
              ruta,
              "DUPLICAR"
            );
          } else {
            // ha habio mensaje de error, hay que ir a portada
            this.router.navigate(["/portada"]);
          }
        } else {
          // para depuración no se tienen en cuenta los permisos de formulario
          this.permisoFormulario = true;
          this.permisobtnNuevo = true;
          this.permisobtnImportar = true;
          this.permisobtnGuardar = true;
          this.permisobtnEliminar = true;
          this.permisobtnDuplicar = true;
        }
      });

    /*     this.permisoFormulario = this.visualizacionAccion(ruta, 'VER');
    if (!this.permisoFormulario) { this.router.navigate(['/portada']) };
    this.permisobtnNuevo = this.visualizacionAccion(ruta, 'NUEVO');
    if (this.permisobtnNuevo==false) this.addbut.emit(false);
    this.permisobtnGuardar = this.visualizacionAccion(ruta, 'MODIFICAR');
    this.permisobtnEliminar = this.visualizacionAccion(ruta, 'ELIMINAR');
    this.permisobtnDuplicar = this.visualizacionAccion(ruta, 'DUPLICAR'); */

    //
    this.initializeTranslate();
    this.getTable();
    this.reloadTable();
    // this.getData()
    this.closepanel();
    this.updatePanel();
  }

  paginatorManage() {
    if (this.paginator) {
      this.paginator.page.subscribe((page) => {
        const paginator = new Paginator(
          page.pageIndex + 1,
          page.pageSize,
          "",
          "ASC"
        );
        if (this.dataSource.sort.direction != "") {
          paginator.orderby = this.table.columns.find(
            (find) => this.dataSource.sort.active == find.name
          ).BBDD;
          paginator.ordertype = this.dataSource.sort.direction.toUpperCase();
        } else {
          paginator.orderby = this.table.columns[1].BBDD;
        }
        this.loadData(paginator);
      });
    }
  }
  sortManage() {
    if (this.sort) {
      this.sort.sortChange.subscribe((sort) => {
        const paginator = new Paginator(1, this.paginator.pageSize, "", "ASC");
        if (this.dataSource.sort.direction != "") {
          paginator.orderby = this.table.columns.find(
            (find) => this.dataSource.sort.active == find.name
          ).BBDD;
          paginator.ordertype = this.dataSource.sort.direction.toUpperCase();
        } else {
          paginator.orderby = this.table.columns[1].BBDD;
        }

        this.loadData(paginator);
      });
    }
  }

  actionManage(action, row) {
    switch (action) {
      case "duplicar":
        this.duplicateRow(row);
        break;
      case "borrar":
        this.borrar(row);
        break;

      default:
        break;
    }
  }

  permisos(action) {
    let ret = false;
    if (action == "borrar" && this.permisobtnEliminar) ret = true;
    if (action == "duplicar" && this.permisobtnDuplicar) ret = true;
    if (action == "modificar" && this.permisobtnGuardar) ret = true;
    return ret;
  }

  visualizacionAccion(ruta: string, accion: string): boolean {
    if (sessionStorage.getItem("currentUser") != null) {
      var permisos = new usuario_Respuesta().deserialize(
        JSON.parse(sessionStorage.getItem("currentUser"))
      ).DatosResult.Permisos;
      if (permisos.length > 0) {
        var permisosboton = permisos.filter(
          (x) =>
            x.NombreAccion.trim().toUpperCase() == accion && x.Ventana == ruta
        );
        if (permisosboton.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  visualizacionAccionAPI(permisos, ruta: string, accion: string): boolean {
    if (permisos.length > 0) {
      var permisosboton = permisos.filter(
        (x) =>
          x.NombreAccion.trim().toUpperCase() == accion && x.Ventana == ruta
      );
      if (permisosboton.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  borrar(row: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.translation.BorrarRegistro,
        texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas,
        ok: this.translation.Confirmar,
        ko: this.translation.Cancelar,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "SI") {
        this.manageDel(this.tableName, row);
      }
    });
  }

  manageDel(tableName, row) {
    switch (tableName) {
      case "perfilUsuarios":
        this.delUserType(row.pkId);
        break;

      case "usuarios":
        this.delUser(row.pkId);
        break;

      case "gruposinternet":
        this.delInternetGroup(row.pkId);
        break;
      
      case "formaspago":
        this.delFormasPago(row.pkId);
        break;

      case "tipossesion":
        this.delTipoSesion(row.pkId);
        break;

      case "sinbutaca":
        this.delSinButaca(row.pkId);
        break;
      
      case "cliente":
        this.delClient(row);
        break;

      case "clientGroup":
        this.delClientGroup(row);
        break;
      
      case "tarifas":
        this.deltarifas(row);
        break;

      case "tbGruposTarifas":
        this.delGrupoTarifa(row);
        break;

      case "tbTiendaProductos":
        this.delTiendaProductos(row);
        break;

      case "tbReglasNegocio":
        this.delReglaNegocio(row);
        break;
      
      case "grupoentrada":
        this.delGrupoEntrada(row.pkId);
        break;

      case "entryType":
        this.deltEntryType(row);
        break;

      case "itemsMotivosGrupos":
        this.delItemsMotivosGrupos(row);
        break;

      case "grupoTipoBono":
        this.delGrupoTipoBono(row);
        break;

      case "gruporecintos":
        this.delGrupoRecintos(row);
        break;

      case "recintosVisitas":
        this.delRecintosVisitas(row);
        break;

      case "gruposactividadtaller":
        this.delGruposActividadTaller(row);
        break;

      case "tipobono":
        this.delTipoBono(row);
        break;
      
      case "categorias":
        this.delCategorias(row);
        break;
      
      case "tiposreservas":
        this.delTiposReservas(row);
        break;
      
      case "festivos":
        this.delDiasdeCierre(row);
        break;
      
      case "tbTiposCodigosPromocionales":
        this.delTiposCodigosPromocionales(row);
        break;
      
      case "tbCodigosPromocionales":
        this.delCodigoPromocional(row);
        break;

      case "tbCodigosPromocionalesBines":
        this.delCodigoPromocionalBin(row);
        break;
      
      case "tpvs":
        this.delTpvs(row);
        break;
      
      case "clientType":
        this.delTiposCliente(row);
        break;
      
      case "typeAccessControl":
        this.delTiposControlAccesos(row);
        break;
      
      case "espectaculos":
        this.delEspectaculos(row);
        break;
      
      case "sesionesespectaculos":
        this.delSesionEspectaculo(row);
        break;

      case "tbCategoriasJerarquicas":
        this.deleteCategoriasJerarquicas(row.pkId);
        break;
      
      default:
        break;
    }
  }

  deleteCategoriasJerarquicas(pkdId: number) {
    this.conectarApiService.deleteCategoriasJerarquicas(pkdId)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data?.datosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            if (data?.mensajes?.length) {
              data.mensajes.forEach(mensaje => {
                this.snackvar_service.openSnackBar(
                  this.translation['categoriajeraquica']['errorDeleteResponse'][mensaje.codigoMensaje],
                  "",
                  "red-snackbar"
                );
              })
            }
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delCategorias(row) {
    this.categoriasService
      .delCategoria(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delTiposReservas(row) {
    this.categoriasService
      .delTiposReservas(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delDiasdeCierre(row) {
    this.diasdeCierreService
      .delDiasdeCierre(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delTiposCodigosPromocionales(row) {
    this.TiposCodigoPromocionesService.delTipoPromo(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delCodigoPromocional(row) {
    this.TiposCodigoPromocionesService.delCodigoPromocional(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delCodigoPromocionalBin(row) {
    this.codigoPromocionalBinesService.deleteCodigoPromocionalBin(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              "",
              "",
              "red-snackbar",
              "ERROR_BORRAR_REGISTRO"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delTpvs(row) {
    this.servicioAPI
      .delTpv(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null && data.DatosResult != false) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delGruposActividadTaller(row) {
    this.gruposActividadTallerService
      .delGruposActividadTaller(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delTipoBono(row) {
    this.bonoTypeBono
      .delTipoBono(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null && data.DatosResult != false) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delRecintosVisitas(row) {
    this.recintosVisitaService
      .delRecintos(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data === undefined) {
            throw new Error(
              "A ocurrido un error dentro porque data es undefined"
            );
          } else if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        //Aqui esta el control de error
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delGrupoRecintos(row) {
    this.grupoRecintosService
      .delGrupoRecintos(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delGrupoTipoBono(row) {
    this.grupoTipoBonoService
      .delGrupoTipoBono(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delItemsMotivosGrupos(row) {
    this.imgroupService
      .delItemsMotivosGrupo(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  deltEntryType(row) {
    this.servicioAPI
      .delTiposEntradas(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  deltarifas(row) {
    this.servicioAPI
      .delTarifas(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delGrupoTarifa(row) {
    this.servicioAPI
      .delGrupoTarifa(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  
  delTiendaProductos(row) {
    this.tiendaProductoService
      .deleteProductoTienda(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.datosResult != null && data.datosResult?.resultado === true) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {

            const cod_message = data.mensajes[0] ? data.mensajes[0].codigoMensaje : '';
            const messageTranslate = cod_message ? this.translation['productosTienda'][cod_message] : this.translation['CodErr_-23'];

            this.snackvar_service.openSnackBar(
              messageTranslate,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delReglaNegocio(row) {
    this.servicioAPI
      .delReglaNegocio(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
          if (data.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
                );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delClientGroup(row) {
    this.editClientService
      .delGruposCliente(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new grupos_cliente_Respuesta().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delClient(row) {
    this.clientService.delClient(row.pkId, row.email).subscribe(
      (result) => {
        if (result.DatosResult != null) {
          this.snackvar_service.openSnackBar(
            this.translation.DatosBorradosCorrectamente,
            "",
            "green-snackbar"
          );
          this.reloadTable();
        } else {
          this.snackvar_service.openSnackBar(
            result.Mensajes[0].DescripcionMensaje,
            "",
            "red-snackbar"
          );
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  delUser(pkId) {
    this.servicioAPI
      .delUsuarios(pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new Usuario_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  delUserType(pkId) {
    this.servicioAPI
      .delTiposUsuario(pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new user_types_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  delGrupoEntrada(pkId) {
    this.servicioAPI
      .delGruposTiposEntrada(pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI: any = data;
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            // this.dataSource.data.splice(,1)
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  delFormasPago(pkId) {
    this.servicioAPI
      .delFormasPago(pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new formas_pago_Respuesta().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            // this.dataSource.data.splice(,1)
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              data.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  delInternetGroup(pkId) {
    this.servicioAPI
      .delGruposdeInternet(pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new user_types_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    // this.editando = false;
  }

  delTipoSesion(pkId) {
    this.servicioAPI
      .delTipoSesion(pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new user_types_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    // this.editando = false;
  }

  delSinButaca(pkId) {
    this.servicioAPI
      .delSinButaca(pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new user_types_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    // this.editando = false;
  }

  delTiposCliente(row) {
    this.servicioAPI
      .delTipoCLiente(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new user_types_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    // this.editando = false;
  }

  delTiposControlAccesos(row) {
    this.servicioAPI
      .delTipoControl(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new user_types_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    // this.editando = false;
  }

  delEspectaculos(row) {
    this.servicioAPI
      .deEspectaculo(row.pkId)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new user_types_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    // this.editando = false;
  }

  delSesionEspectaculo(row) {
    let Fecha = "";
    let Hora = "";
    if (moment(row.Fecha).isValid()) {
      Fecha = moment(row.Fecha).format("DD/MM/yyyy");
    }

    let parts = row.HoraInicio.split(":");
    Hora = parts[0] + ":" + parts[1];

    this.servicioAPI
      .deSesionEspectaculo(row.EspectaculoId, Fecha, Hora)
      .pipe(first())
      .subscribe(
        (data) => {
          const respuestaAPI = new user_types_response().deserialize(data);
          if (respuestaAPI.DatosResult != null) {
            this.snackvar_service.openSnackBar(
              this.translation.DatosBorradosCorrectamente,
              "",
              "green-snackbar"
            );
            this.reloadTable();
          } else {
            this.snackvar_service.openSnackBar(
              respuestaAPI.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    // this.editando = false;
  }

  checkParams() {
    this.route.queryParams.subscribe((params) => {
      let param = params["pkId"];
      //let dataJson = '{"nombre":"jordi","contrasena":"Jordijordi2!","pkid":1,"ruta":"/Clientes/Cliente"}';
      if (param) {
        this.filterService.filterByPkId(param);
        this.dataSource.data.map((data) => {
          if (data.pkId == param && !this.b_checkParams) {
            this.editRow(data);
            this.b_checkParams = true;
          }
        });
      }
    });
  }
  getData() {
    this.filterService.dataTableFilter$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data != "") {
          this.dataSource = data;
          this.dataSource.sort = this.sort;
          if (!this.b_checkParams) {
            this.checkParams();
          }
          if (this.dataSource["page"] == 1) {
            if (this.paginator) this.paginator.firstPage();
          }
          //this.dataSource.paginator = this.paginator;
          //this.paginator.length=100
          // this.paginatorLength=100
          // this.paginatorSize = 5

          if (this.paginator == undefined) {
            this.paginator = data.paginator;
          }

          if (this.paginator) this.paginator.length = data.totalResultados;
          if (this.paginator) this.paginator.pageSize = data.pagelements;

          this.cdRef.detectChanges();
        }
        // this.paginatorLength=data.totalResultados
      });
  }
  loadData(paginator) {
    if (this.parent_pkId != "")
      if (this.subparent_pkId != "")
        this.filterService.manageGetDataDB(
          this.tableName,
          paginator,
          this.parent_pkId,
          this.subparent_pkId
        );
      else
        this.filterService.manageGetDataDB(
          this.tableName,
          paginator,
          this.parent_pkId
        );
    else this.filterService.manageGetDataDB(this.tableName, paginator);
  }
  reloadTable() {
    const paginator = new Paginator(
      1,
      this.table != undefined ? this.table.maxItems : 10,
      "pkId",
      "ASC"
    );
    if (this.table && this.table["columns"]) {
      paginator.orderby = this.table.columns[1].BBDD;
    }

    this.loadData(paginator);
  }
  getTable() {
    this.filterService.table$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.table = data;

        // switch (data.tableName) {
        //   case "tbTiposPromocionEntradas":
        //      //this.table.columns = this.table.columns.filter(x => x.name !== 'dtFechaInicio' && x.name !== 'dtFechaFin')
    
        //      this.table.columns = this.table.columns.map(row => {
        //       if (row.name == 'dtFechaInicio' || row.name == 'dtFechaFin')
        //         return { ...row, required: false, column: false, columnActive : ""}
        //       else
        //         return { ...row}
        //      });
        //     break;
        //   default:
        //     break;
        // }

        if (this.table && this.table["columns"] != null) {
          this.columnsToDisplay = [];
          this.showTable = false;
          this.displayedColumnsFunction();
        }
      });
  }

  emitirTotales() {
    let dataSource = this.dataSource;
    switch (this.tableName) {
      case "tbCodigoPromocionalTiposBonoEntradas":
      case "tbCodigoPromocionalTiposBonoEntradas_calco":
        let bitotal = 0,
          preciototal = 0,
          bicomisiontotal = 0,
          comisiontotal = 0;
        
        let divisaObjectStorage = localStorage.getItem("divisaObject");

        let divisaObject: DivisaObject = divisaObjectStorage 
          ? JSON.parse(divisaObjectStorage)
          : {
            "PkId": "2",
            "pkId": "2",
            "Nombre": "EUROS",
            "simbolo": "€",
            "NumeroDecimales": "2",
            "OpsTarjeta": "True",
            "PosicionDivisa": "D",
            "SimboloDecimal": ",",
            "SimboloDerecha": "",
            "CodISO": "978",
            "SimbSeparacionMiles": ""
          }
      
        const { NumeroDecimales, SimboloDecimal } = divisaObject;
      
        const NumeroDecimalesInt = parseInt(NumeroDecimales);

        dataSource.data.forEach((f) => {

          const {
            BaseImponible,
            Precio,
            ComisionBI,
            Comision,
          } = f;

          const valuesParsed = {
            BaseImponible: BaseImponible
              ? BaseImponible.includes(",")
                ? BaseImponible.replace(",", ".") 
                : BaseImponible
              : 0,

            Precio: Precio
              ? Precio.includes(",")
                ? Precio.replace(",", ".") 
                : Precio
              : 0,
            ComisionBI: ComisionBI
              ? ComisionBI.includes(",")
                ? ComisionBI.replace(",", ".") 
                : ComisionBI
              : 0,
            Comision: Comision
              ? Comision.includes(",")
                ? Comision.replace(",", ".") 
                : Comision
              : 0,
          };

          bitotal += parseFloat(valuesParsed.BaseImponible);
          preciototal += parseFloat(valuesParsed.Precio);
          bicomisiontotal += parseFloat(valuesParsed.ComisionBI);
          comisiontotal += parseFloat(valuesParsed.Comision);
        });
        this.totales.emit({
          tabla: this.tableName,
          TipoCodigoPromocionalTipoBonoId:
            dataSource.data[0].CodigoPromocionalTipoBonoId,
          BaseImponibleTotal: bitotal.toFixed(4).replace(".", SimboloDecimal),
          PrecioTotal: preciototal.toFixed(NumeroDecimalesInt).replace(".", SimboloDecimal),
          BIComisionTotal: bicomisiontotal.toFixed(4).replace(".", SimboloDecimal),
          ComisionTotal: comisiontotal.toFixed(NumeroDecimalesInt).replace(".", SimboloDecimal),
        });
        break;
      default:
        break;
    }
  }

  async initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }
  displayedColumnsFunction() {
    this.table.columns.map((elem) => {
      if (elem.columnActive != "" && elem.column) {
        this.showTable = true;
        this.columnsToDisplay.push(elem.name);
      }
    });
  }

  closepanel() {
    this.panelService.closePanel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((close) => {
        this.customanpanel = undefined;
        this.closePanelChangeCss();
      });
  }
  closePanelChangeCss() {
    let classElem;

    classElem = document.getElementsByClassName("component");
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = "block";
    }
  }

  updatePanel() {
    this.panelService.savePanel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((save) => {
        this.reloadTable();
      });
  }

  duplicateRow(row) {
    this.alertaService.limpiar();
    this.customanMode = "D";
    this.customanPkId = row.pkId;
    switch (this.tableName) {
      case "tbTiposCodigosPromocionales":
        this.customanpanel = EditTipoCodigoPromocionesComponent;
        this.editChangeCss();
        break;
      case "perfilUsuarios":
        this.customanpanel = ProfileSaveComponent;
        this.editChangeCss();
        break;
      case "usuarios":
        this.customanpanel = EditUserComponent;
        this.editChangeCss();
        break;
      case "tarifas":
        this.customanpanel = EditTarifaComponent;
        this.editChangeCss();
        break;

      case "tbGruposTarifas":
        this.customanpanel = EditGruposTarifasComponent;
        this.editChangeCss();
        break;

      case "tbTiendaProductos":
        this.customanpanel = EditProductoComponent;
        this.editChangeCss();
        break;

      case "tbReglasNegocio":
        this.customanpanel = EditReglasDeNegocioComponent;
        this.editChangeCss();
        break;
      
      case "cliente":
        this.customanpanel = EditClientComponent;
        this.editChangeCss();
        break;
      case "gruposinternet":
        this.customanpanel = EditGroupComponent;
        this.editChangeCss();
        break;
      case "formaspago":
        this.customanpanel = EditFormasPagoV11Component;
        this.editChangeCss();
        break;
      case "tipossesion":
        this.customanpanel = EditSessionTypeComponent;
        this.editChangeCss();
        break;
      case "sinbutaca":
        this.customanpanel = EditSinButacasComponent;
        this.editChangeCss();
        break;
      case "clientGroup":
        this.customanpanel = EditClientGroupComponent;
        this.editChangeCss();
        break;
      case "formaspago":
        this.customanpanel = EditFormasPagoV11Component;
        this.editChangeCss();
        break;
      case "tipobono":
        this.customanpanel = EditTipoBonoComponent;
        this.editChangeCss();
        break;
      case "grupoentrada":
        this.customanpanel = EditGruposTipoEntradaV11Component;
        this.editChangeCss();
        break;

      case "entryType":
        this.customanpanel = EditEntryTypeComponent;
        this.editChangeCss();
        break;
      case "itemsMotivosGrupos":
        this.customanpanel = EditItemsMotivosGruposV11Component;
        this.editChangeCss();
        break;
      case "grupoTipoBono":
        this.customanpanel = EditGrupoTipoBonoV11Component;
        this.editChangeCss();
        break;
      case "gruporecintos":
        this.customanpanel = EditGrupoRecintosComponent;
        this.editChangeCss();
        break;
      case "recintosVisitas":
        this.customanpanel = EditRecintosVisitaComponent;
        this.editChangeCss();
        break;
      case "gruposactividadtaller":
        this.customanpanel = EditGruposActividadTallerV11Component;
        this.editChangeCss();
        break;
      case "categorias":
        this.customanpanel = EditCategoriasComponent;
        this.editChangeCss();
        break;
      case "tiposreservas":
        this.customanpanel = EditTiposreservaComponent;
        this.editChangeCss();
        break;
      case "festivos":
        this.customanpanel = EditDiascierreComponent;
        this.editChangeCss();
        break;
      case "tbCodigosPromocionales":
        this.customanpanel = EditCodigoPromocionalComponent;
        this.editChangeCss();
        break;

      case "tbCodigosPromocionalesBines":
        this.customanpanel = EditCodigoPromocionalBinesComponent;
        this.editChangeCss();
        break;
      
      case "tpvs":
        this.customanpanel = EditTpvsComponent;
        this.editChangeCss();
        break;
      case "clientType":
        this.customanpanel = EditClientTypeComponent;
        this.editChangeCss();
        break;
      case "typeAccessControl":
        this.customanpanel = EditAccessControlTypeComponent;
        this.editChangeCss();
        break;
      case "espectaculos":
        this.customanpanel = EditEspectaculosComponent;
        this.editChangeCss();
        break;
      case "sesionesespectaculos":
        this.customanpanel = EditSesionesConButacasComponent;
        this.editChangeCss();
        break;
      case "tbCategoriasJerarquicas":
        this.customanpanel = EditCategoriasJerarquias;
        this.editChangeCss();
        break;

      default:
        break;
    }

    this.cdRef.detectChanges();
  }

  editRow(row) {
    console.log('Row::', row);
    this.selectedRowIndex = row.pkId;
    this.alertaService.limpiar();
    this.customanMode = "U";
    this.customanPkId = row.pkId;
    switch (this.tableName) {
      case "perfilUsuarios":
        this.customanpanel = ProfileSaveComponent;
        this.editChangeCss();
        break;
      case "usuarios":
        this.customanpanel = EditUserComponent;
        this.editChangeCss();
        break;
      case "tarifas":
        this.customanpanel = EditTarifaComponent;
        this.editChangeCss();
        break;
      
      case "tbGruposTarifas":
        this.customanpanel = EditGruposTarifasComponent;
        this.editChangeCss();
        break;

      case "tbTiendaProductos":
        this.customanpanel = EditProductoComponent;
        this.editChangeCss();
        break;
      
      case "cliente":
        this.customanpanel = EditClientComponent; //probar
        this.editChangeCss();
        break;
      case "gruposinternet":
        this.customanpanel = EditGroupComponent;
        this.editChangeCss();
        break;
      case "formaspago":
        this.customanpanel = EditFormasPagoV11Component;
        this.editChangeCss();
        break;
      case "tipossesion":
        this.customanpanel = EditSessionTypeComponent;
        this.editChangeCss();
        break;
      case "sinbutaca":
        this.customanpanel = EditSinButacasComponent;
        this.editChangeCss();
        break;
      case "clientGroup":
        this.customanpanel = EditClientGroupComponent; //
        this.editChangeCss();
        break;
      case "formaspago":
        this.customanpanel = EditFormasPagoV11Component; //
        this.editChangeCss();
        break;
      case "entryType":
        this.customanpanel = EditEntryTypeComponent;
        this.editChangeCss();
        break;
      case "itemsMotivosGrupos":
        this.customanpanel = EditItemsMotivosGruposV11Component;
        this.editChangeCss();
        break;
      case "tipobono":
        this.customanpanel = EditTipoBonoComponent;
        this.editChangeCss();
        break;
      case "grupoentrada":
        this.customanpanel = EditGruposTipoEntradaV11Component;
        this.editChangeCss();
        break;
      case "recintosVisitas":
        this.customanpanel = EditRecintosVisitaComponent;
        this.editChangeCss();
        break;

      case "grupoTipoBono":
        this.customanpanel = EditGrupoTipoBonoV11Component;
        this.editChangeCss();
        break;
      case "gruporecintos":
        this.customanpanel = EditGrupoRecintosComponent;
        this.editChangeCss();
        break;
      case "gruposactividadtaller":
        this.customanpanel = EditGruposActividadTallerV11Component;
        this.editChangeCss();
        break;
      case "categorias":
        this.customanpanel = EditCategoriasComponent;
        this.editChangeCss();
        break;
      case "tiposreservas":
        this.customanpanel = EditTiposreservaComponent;
        this.editChangeCss();
        break;
      case "festivos":
        this.customanpanel = EditDiascierreComponent;
        this.editChangeCss();
        break;
      case "tbTiposCodigosPromocionales":
        this.customanpanel = EditTipoCodigoPromocionesComponent;
        this.editChangeCss();
        break;
      
      case "tbCodigosPromocionales":
        this.customanpanel = EditCodigoPromocionalComponent;
        this.editChangeCss();
        break;

      case "tbCodigosPromocionalesBines":
        this.customanpanel = EditCodigoPromocionalBinesComponent;
        this.editChangeCss();
        break;
      
      case "tpvs":
        this.customanpanel = EditTpvsComponent;
        this.editChangeCss();
        break;
      case "clientType":
        this.customanpanel = EditClientTypeComponent;
        this.editChangeCss();
        break;
      case "typeAccessControl":
        this.customanpanel = EditAccessControlTypeComponent;
        this.editChangeCss();
        break;
      case "espectaculos":
        this.customanpanel = EditEspectaculosComponent;
        this.editChangeCss();
        break;
      case "sesionesespectaculos":
        this.customanpanel = EditSesionesConButacasComponent;
        this.editChangeCss();
        break;
        case "tbCategoriasJerarquicas":
          this.customanpanel = EditCategoriasJerarquias;
          this.editChangeCss();
          break;
      default:
        break;
    }
  }
  editChangeCss() {
    let classElem;
    classElem = document.getElementsByClassName("component");
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = "none";
    }
  }
}
