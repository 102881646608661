import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
	HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Paginator } from "@sharedV11/classes/paginator/paginator";
import {
	IGrupoTarifa,
	IUGrupoTarifaAPI,
	Mode,
} from "@sharedV11/classes/tarifas/grupostarifas";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import {
	filters,
	pagination,
} from "@sharedV11/services/interfaces/paginator.interface";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IProductoTienda, ListRequestBody, PayloadInsertProducto, Result, ResultMS, TipoEntradaInsert } from "./productos-tienda.interface";

const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
	}),
};

@Injectable({
	providedIn: "root",
})
export class TiendaProductosService {
	gruposTarifas = [];

	conexionIACPOS: number = parseInt(localStorage.getItem("conexionIACPOS"));

	tarifas = [];
	totalTarifasRows = 0;

	clientes = [];
	totalClientesRows = 0;

	private extractData(res: Response) {
		let body = res;
		return body || {};
	}

	constructor(
		private http: HttpClient,
		private tokenService: ApiTokenService,
		private global: GlobalService,
		private servicioAPI: ConectarApiService,
		private httpErrorService: HttpErrorsService
	) {}

	cargarListaGruposTarifas(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			this.servicioAPI.traerGruposTarifas().subscribe(
				(data) => {
					this.gruposTarifas = data.DatosListas;
					resolve(true);
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
					reject(error);
				}
			);
		});

		return promise;
	}

	getGruposTarifasNombres(gruposTarifasPkIds = ""): string {
		if (!gruposTarifasPkIds) return "";

		const arrGruposTarifasPkIds = gruposTarifasPkIds.split(",");

		const gruposTarifasNombres = this.gruposTarifas
			.filter((grupoTarifa) => arrGruposTarifasPkIds.includes(grupoTarifa.Id))
			.map((grupoTarifa) => grupoTarifa.Nombre)
			.join(",");

		return gruposTarifasNombres;
	}

	filtrarGruposTarifas(): any[] {
		return [...this.gruposTarifas];
	}

	getGruposTarifasAsignadasJoin(gruposTarifasAsignados = []): string {
		const gruposTarifasPkids = gruposTarifasAsignados.map(
			(grupoTarifa) => grupoTarifa.Id
		);

		return gruposTarifasPkids.join(",");
	}

	getArrGruposTarifasAsignadas(stringIds = ""): any[] {
		if (!stringIds) return [];

		const arrIds = stringIds.split(",");

		const gruposTarifasAsignadas = this.gruposTarifas.filter((grupoTarifa) =>
			arrIds.includes(grupoTarifa.Id)
		);

		return gruposTarifasAsignadas;
	}

	async getTbGruposTarifas(paginator, filters) {
		const response = await this.servicioAPI.getGruposTarifasAsync(
			paginator,
			filters
		);
		/* if (response?.DatosResult !== null) {
			this.tarifas = response?.DatosResult?.ListaTarifas;
			this.totalTarifasRows = Number(
				response?.DatosResult?.TotalResultados || 0
			);
		} */
	}

	filtrarTarifas(tarifasAsignadasIds = []): any[] {
		if (tarifasAsignadasIds.length === 0) return [...this.tarifas];

		const tarifasAsignadasPkids = tarifasAsignadasIds;

		const tarifasFiltradas = this.tarifas.filter((tarifa) => {
			return !tarifasAsignadasPkids.includes(tarifa.Id);
		});

		return tarifasFiltradas;
	}

	getTarifasAsignadasJoin(tarifasAsignadas = []): string {
		const tarifasPkids = tarifasAsignadas.map((tarifa) => tarifa.Id);

		return tarifasPkids.join(",");
	}

	getArrTarifasAsignadas(stringIds = ""): any[] {
		if (!stringIds) return [];

		const arrIds = stringIds.split(",");

		const tarifasAsignadas = this.tarifas.filter((tarifa) =>
			arrIds.includes(tarifa.Id)
		);

		return tarifasAsignadas;
	}

	getClientesAsignadosJoin(clientesAsignados = []): string {
		const clientesPkids = clientesAsignados.map((cliente) => cliente.Id);

		return clientesPkids.join(",");
	}

	getArrClientesAsignados(stringIds = ""): any[] {
		if (!stringIds) return [];

		const arrIds = stringIds.split(",");

		const clientesAsignados = this.clientes.filter((cliente) =>
			arrIds.includes(cliente.Id)
		);

		return clientesAsignados;
	}

	filtrarClientes(clientesAsignadosIds = []): any[] {
		if (clientesAsignadosIds.length === 0) return [...this.clientes];

		const clientesAsignadosPkIds = clientesAsignadosIds;

		const clientesFiltrados = this.clientes.filter(
			(client) => !clientesAsignadosPkIds.includes(client.Id)
		);

		return clientesFiltrados;
	}

	findGrupoTarifaByPkId(pkId: string): Observable<any> {
		let peticion: any = {};
		peticion.clienteAPI = this.global.clienteapi; //TODO
		peticion.pkId = pkId;

		const response = this.http
			.post<any>(
				this.tokenService.getConfig("API_URL") +
					"Customan/ObtenerGruposTarifas",
				JSON.stringify(peticion),
				httpOptions
			)
			.pipe(
				map((data) => {
					return data;
				}),
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);
		return response;
	}

	insUpGrupoTarifa(grupoTarifa: IGrupoTarifa, mode: Mode): Observable<any> {
		//Montamos la peticion insercion/Update
		let peticion: IUGrupoTarifaAPI = grupoTarifa;
		peticion.clienteAPI = this.global.clienteapi; //TODO
		peticion.Funcion = mode;
		//Enviamos la peticion
		return this.http
			.post<any>(
				this.tokenService.getConfig("API_URL") +
					"Customan/InsertarActualizarProductosTienda",
				JSON.stringify(peticion),
				httpOptions
			)
			.pipe(
				map(this.extractData),
				catchError(this.handleError<any>("InsertarActualizarGruposTarifas"))
			);
	}

	listProductosTienda(
		pagination?: pagination,
		filters?: filters
	): Observable<Result> {
		const username = JSON.parse(sessionStorage.getItem("currentUser"))
			? JSON.parse(sessionStorage.getItem("currentUser")).DatosResult
					.NombreUsuario
			: "";

		let peticion: ListRequestBody = {
			orderby: "",
			ordertype: "",
			page: 1,
			pagelements: 100,
			usuario: username,
		};

		if (pagination) {
			peticion = {
				...peticion,
				...pagination,
				pagelements: pagination.pagelements || peticion.pagelements || 100,
			};
		}

		const URL = `${this.tokenService.getConfig(
			"API_MICROSERVICIOS"
		)}/api/Tienda/ProductosTienda`;

		let params = new HttpParams();

		for (const key in peticion) {
			if (peticion.hasOwnProperty(key)) {
				params = params.set(key, peticion[key]);
			}
		}

		const filtersToParseBoolean = ["ALaVenta"];

		if (filters && filters.length > 0) {
			for (const filter of filters) {
				if (filtersToParseBoolean.includes(filter.name)) {
					params = params.set("name", filter.name);
					params = params.set("value", filter.value == "1" ? "true" : "false");
				} else {
					params = params.set("name", filter.name);
					params = params.set("value", filter.value);
				}
			}
		}

		const response = this.http
			.get<Result>(URL, {
				params,
			})
			.pipe(
				map((data) => {
					return data;
				}),
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);
		return response;
	}

	findProductoTiendaByPkId(
		pkId: string
	): Observable<IProductoTienda | null> {
		const URL = `${this.tokenService.getConfig(
			"API_MICROSERVICIOS"
		)}/api/Tienda/ProductosTienda`;

		let params = new HttpParams();

		params = params.set("name", "pkId");
		params = params.set("value", pkId);

		const response = this.http
			.get<Result>(URL, {
				params,
			})
			.pipe(
				map((data) => {

					if (data?.datosResult?.listado.length > 0) {
						return data.datosResult.listado[0];
					}

					return null;
				}),
				catchError((error: HttpErrorResponse) => {
					return of(null);
				})
			);
		return response;
	}

	parseValuesToInsUpProductoTienda(productoTienda: PayloadInsertProducto) {

		const keysToParseNumber = ["grupoId",
        "grupoAsociadoId", //Fallando cuando es vacío
        "tipoNivelId",
        "tipoPublicoId",
        "tipoVisitanteId",
        "tipoVisitaId",
        "nivelesId",
        "teDatosId",
        "tipoProductoId",
        "divisaId",
				"teclaRapida"
			]

		
		const keysToParseBooleanNumber = ["imprimirPrecio"];

		const keysToParseNumberStock = ["tipoEntradaId", "categoriaJerarquicaId", "proveedorTiendaId"];

		let newProductoTienda = { ...productoTienda };

		for (const key in newProductoTienda.tipoEntrada) {
			if (keysToParseNumber.includes(key)) {
				newProductoTienda.tipoEntrada[key] = newProductoTienda.tipoEntrada[key] ? parseInt(newProductoTienda.tipoEntrada[key]) : null;
			}

			if (keysToParseBooleanNumber.includes(key)) {
				newProductoTienda.tipoEntrada[key] = newProductoTienda.tipoEntrada[key] ? 1 : 0;
			}
		}

		for (const key in newProductoTienda.stock) {
			if (keysToParseNumberStock.includes(key)) {
				newProductoTienda.stock[key] = newProductoTienda.stock[key] ? parseInt(newProductoTienda.stock[key]) : null;
			}
		}

		return newProductoTienda;

	}

	insertProductoTienda(
		productoTienda: PayloadInsertProducto
	): Observable<any> {
		//Montamos la peticion insercion/Update

		const newProductoTienda = this.parseValuesToInsUpProductoTienda(productoTienda);

		const URL = `${this.tokenService.getConfig(
			"API_MICROSERVICIOS"
		)}/api/Tienda/ProductoTienda`;

		const response = this.http
			.post<any>(URL, newProductoTienda, httpOptions)
			.pipe(
				map((data) => data),
				catchError((error: HttpErrorResponse) => {
					console.log({
						errorResponse: error,
					});
					return throwError(error);
				})
			);

		return response;
	}

	updateProductoTienda(
		productoTienda: PayloadInsertProducto
	): Observable<any> {
		//Montamos la peticion insercion/Update

		const newProductoTienda = this.parseValuesToInsUpProductoTienda(productoTienda);

		const URL = `${this.tokenService.getConfig(
			"API_MICROSERVICIOS"
		)}/api/Tienda/ProductoTienda`;

		const response = this.http
			.put<any>(URL, newProductoTienda, httpOptions)
			.pipe(
				map((data) => data),
				catchError((error: HttpErrorResponse) => {
					console.log({
						errorResponse: error,
					});
					return throwError(error);
				})
			);

		return response;
	}

	deleteProductoTienda(pkId: string): Observable<any> {
		if (!pkId) {
			return of(null);
		}

		let params = new HttpParams();

		params = params.set("pkId", pkId);

		const URL = `${this.tokenService.getConfig(
			"API_MICROSERVICIOS"
		)}/api/Tienda/ProductoTienda`;
		// Enviamos la peticion
		const response = this.http
			.delete<any>(URL, {
				params,
			})
			.pipe(
				map((data) => {
					return data;
				}),
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);

		return response;
	}

	/* Lista Microservicio */

	listProveedoresTienda(
		pagination?: pagination,
		filters?: filters
	): Observable<Result> {
		const username = JSON.parse(sessionStorage.getItem("currentUser"))
			? JSON.parse(sessionStorage.getItem("currentUser")).DatosResult
					.NombreUsuario
			: "";

		let peticion: ListRequestBody = {
			orderby: "",
			ordertype: "",
			page: 1,
			pagelements: 100,
			usuario: username,
		};

		if (pagination) {
			peticion = {
				...peticion,
				...pagination,
				pagelements: pagination.pagelements || peticion.pagelements || 100,
			};
		}

		const URL = `${this.tokenService.getConfig(
			"API_MICROSERVICIOS"
		)}/api/Tienda/ProveedoresTienda`;

		let params = new HttpParams();

		for (const key in peticion) {
			if (peticion.hasOwnProperty(key)) {
				params = params.set(key, peticion[key]);
			}
		}

		if (filters && filters.length > 0) {
			for (const filter of filters) {
				params = params.set("name", filter.name);
				params = params.set("value", filter.value);
			}
		}

		const response = this.http
			.get<Result>(URL, {
				params,
			})
			.pipe(
				map((data) => {
					return data;
				}),
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);
		return response;
	}

	listCategoriasJerarquicas(
		pagination?: pagination,
		filters?: filters
	): Observable<Result> {
		const username = JSON.parse(sessionStorage.getItem("currentUser"))
			? JSON.parse(sessionStorage.getItem("currentUser")).DatosResult
					.NombreUsuario
			: "";

		let peticion: ListRequestBody = {
			orderby: "",
			ordertype: "",
			page: 1,
			pagelements: 100,
			usuario: username,
		};

		if (pagination) {
			peticion = {
				...peticion,
				...pagination,
				pagelements: pagination.pagelements || peticion.pagelements || 100,
			};
		}

		const URL = `${this.tokenService.getConfig(
			"API_MICROSERVICIOS"
		)}/api/Maestros/categoriasjerarquicas`;

		let params = new HttpParams();

		for (const key in peticion) {
			if (peticion.hasOwnProperty(key)) {
				params = params.set(key, peticion[key]);
			}
		}

		if (filters && filters.length > 0) {
			for (const filter of filters) {
				params = params.set("name", filter.name);
				params = params.set("value", filter.value);
			}
		}

		const response = this.http
			.get<Result>(URL, {
				params,
			})
			.pipe(
				map((data) => {
					return data;
				}),
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);
		return response;
	}

	listTiendaBotonera(): Observable<ResultMS> {
		const URL = `${this.tokenService.getConfig(
			"API_MICROSERVICIOS"
		)}/api/Tienda/GetTipoEntradaBotonera`;

		let params = new HttpParams();
		params = params.set("Max", "50");

		const response = this.http
			.get<ResultMS>(URL, {
				params,
			})
			.pipe(
				map((data) => {
					return data;
				}),
				catchError((error: HttpErrorResponse) => {
					return of(null);
				})
			);
		return response;
	}

	private handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
