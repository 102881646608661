import { Pipe, PipeTransform } from '@angular/core';
import { Translator } from './services/translator/translator.service';

type typeTranslate = 'UPPER' | 'LOWER' | 'CAPITALIZE';

@Pipe({
  name: 'translateV2',
  pure: false
})
export class TranslatePipeV2 implements PipeTransform {

  data: any = {};

  constructor(private translate: Translator) {
    this.loadData();
  }

  async loadData() {

    this.translate.dataLanguage$.subscribe((data) => {
      if (data) {
        this.data = this.translate.GetTranslations();
      }
    });
  }

  transform(key: string, mode: typeTranslate = 'CAPITALIZE'): any {
    let trad = this.data;
    const value: string = trad[key] || key;
    
    switch (mode) {
      case 'UPPER':
        return value.toUpperCase();
      case 'LOWER':
        return value.toLowerCase();
      case 'CAPITALIZE':
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      default:
        return value;
    }
  }

}
