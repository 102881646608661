import { Component, Input, LOCALE_ID, OnInit } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatPaginatorIntlEses } from "@otrosProveedoresV6/ES-es.paginator";
import {
	APP_FORMATOS_FECHA,
	AppDateAdapter,
} from "@sharedV11/classes/dataAdapter/date-format.adapter";
import {
	BodyExcelFile,
	BodyInsertCodigoPromocionalBines,
	IBin,
} from "@sharedV11/interfaces/microservice/CodigoPromocionalBines/index.interface";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { CodigoPromocionalBinesService } from "@sharedV11/services/components/codigo-promocional-bines/codigo-promocional-bines.service";
import { ExcelService } from "@sharedV11/services/files/excel.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { getLocale } from "@sharedV11/utils/factories";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-import-codigo-promocional-bines",
	templateUrl: "./import-codigo-promocional-bines.component.html",
	styleUrls: ["./import-codigo-promocional-bines.component.scss"],
	providers: [
		{
			provide: DateAdapter,
			useClass: AppDateAdapter,
		},
		{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
		{
      provide: LOCALE_ID,
      useFactory: getLocale,
      deps: [Translator],
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: getLocale,
      deps: [Translator],
    },
	],
})
export class ImportCodigoPromocionalBinesComponent implements OnInit {
	@Input() pkId: string;
	@Input() mode: string;
	@Input() modificar: boolean;
	private setManualValueInputFilter: Function;
	private ngUnsubscribe = new Subject();

	displayedColumns: string[] = ["Banco", "Bin", "Codigo"];

	loading = true;

	editRowsForm: FormGroup;
	importFileForm: FormGroup;

  dataLoaded = false;

	translation: any;
	codigosBinesImport: IBin[] = [];
	codigosPromocionalesList: any[] = [];

	//centros
	centrosSinAsignar = [];
	centrosAsignados = [];
	tipoCentros: any[] = [];

	//Dual List Config
	format = {
		add: "Añadir",
		remove: "Borrar",
		all: "Todos",
		none: "Ninguno",
		direction: DualListComponent.LTR,
		draggable: true,
		locale: "es",
	};

	constructor(
		private translator: Translator,
		private gsCentros: GlobalServicCentros,
		private panelService: PanelService,
		private fb: FormBuilder,
		private codigoPromocionalBinesService: CodigoPromocionalBinesService,
		private snackvar_service: SnackbarInfoService,
		private httpErrorService: HttpErrorsService,
		private excelService: ExcelService
	) {
		this.initializeFormWithoutData();
	}

	async ngOnInit() {
		try {
			// this.initializeFormWithoutData();
			this.initializeTranslate();
			await this.loadCentros(),
				this.codigoPromocionalBinesService
					.listCodigosPromocionales()
					.subscribe((data) => {
						this.codigosPromocionalesList =
							data?.DatosResult?.ListaCodigoPromocional ?? [];

						this.loading = false;
					});
		} catch (err) {
			console.error(err);
		}
	}

	initializeTranslate() {
		this.translator.dataLanguage$.subscribe((data) => {
			if (data) {
				this.translation = this.translator.GetTranslations();
				this.format = {
					add: this.translation.Anadir,
					remove: this.translation.Borrar,
					all: this.translation.Todos,
					none: this.translation.Ninguno,
					direction: DualListComponent.LTR,
					draggable: true,
					locale: "es",
				};
			}
		});
	}

	onFileChange(event) {
		const file: File = event.target.files[0];
		this.importFileForm.get("fileName").setValue(file.name);
		this.importFileForm.get("file").setValue(file);
	}

	initializeFormWithoutData() {
		this.importFileForm = this.fb.group({
			file: [null, Validators.required],
			fileName: ["", Validators.required],
		});

		this.editRowsForm = this.fb.group({
			rows: this.fb.array([]),
		});
	}

	updateFormRows(data: IBin[]) {
		console.log({
			dataFormRows: data,
		});
		const rows = data.map((item) => this.createRow(item));
		this.editRowsForm.setControl("rows", this.fb.array(rows));

		this.codigosBinesImport = data;

    this.dataLoaded = true;
	}

	get formArray(): FormArray {
		return this.editRowsForm.get("rows") as FormArray;
	}

	createRow(item: IBin): FormGroup {
		console.log({
			action: "createRow",
			item,
		});
		return this.fb.group({
			Nombre: [item.Nombre, Validators.required],
			NumeroTarjeta: [item.NumeroTarjeta, Validators.required],
			CodigoPromocional: [item.CodigoPromocional, Validators.required],
		});
	}

	async loadCentros() {
		await this.gsCentros.getCentros();
	}

	getErrorMessage(field: string, index: number) {
		const formArray = this.editRowsForm.get("rows") as FormArray;

    if (formArray && formArray.length > 0) {
      const formGroup = formArray.at(index);
      let fc_pv = formGroup.get(field);
      if (fc_pv) {
        if (fc_pv.errors) {
          if (fc_pv.errors.required) {
            return this.translation["CampoRequerido"];
          } else if (field === "NumeroTarjeta") {
            if (fc_pv.hasError("pattern")) {
              return this.translation["SoloNumeros"];
            }
          } else if (field === "Nombre") {
            if (fc_pv.hasError("pattern")) {
              return this.translation["SoloLetras"];
            }
          }
        }
      }

      return "Error";
    }
	}

	validateFields(field: string, index: number) {
		return this.isValidAnyField(field, index);
	}

	isValidAnyField(field: string, index: number) {
		const fc_pv = this.editRowsForm.get("rows") as FormArray;

    if (fc_pv && fc_pv.length > 0) {
      const formGroup = fc_pv.at(index);
      const fc = formGroup.get(field);
  
      return fc.errors !== null || fc.invalid ? true : false;
    }

    return false;
	}

	closeInputFilterCodigoPromocional(eventValues, index: number) {
		const formArray = this.editRowsForm.get("rows") as FormArray;
		const formGroup = formArray.at(index);
		formGroup.get("CodigoPromocional").setValue(eventValues.value);
	}

	callbacksOnSelectCodigoPromocional(event) {
		this.setManualValueInputFilter = event.methods.setManualValueForm;
	}

	discard() {
		this.codigosBinesImport = [];
		this.updateFormRows([]);
		this.importFileForm.reset();
		this.loading = false;
	}

	discardCentros() {
		this.centrosAsignados = [];
	}

	get formValue() {
		return this.editRowsForm.value;
	}

	get formRows() {
		return this.editRowsForm.get("rows")
			? this.editRowsForm.get("rows").value
			: [];
	}

	submit(cerrarpanel: boolean = true) {
		if (this.editRowsForm.valid) {
			this.manageApi(this.mode);
		} else {
			this.snackvar_service.openSnackBar(
				this.translation["ErrorFaltanDatosPorIntroducir"],
				"",
				"red-snackbar"
			);
		}
	}

	downloadTemplate() {
		//Obtener el archivo de /assets/files/BinTemplate.xlsx y descargarlo
		const link = document.createElement("a");
		link.href = "/assets/files/BinTemplate.xlsx";
		link.download = "BinTemplate.xlsx";
		link.click();

		console.log("Descargando archivo BinTemplate.xlsx");
	}

	async submitImport() {
		this.loading = true;

		//Obtener los datos del archivo importado
		const file: File = this.importFileForm.get("file").value;

		if (!file) {
			this.snackvar_service.openSnackBar(
				this.translation["ErrorFaltanDatosPorIntroducir"],
				"",
				"red-snackbar"
			);
			this.loading = false;
			return;
		}

		const data = await this.excelService.parseFile<BodyExcelFile>(file);
		this.loading = false;

		const dataParsed: IBin[] = data.map((item) => {
			return {
				Nombre: item.BANCO || "",
				CodigoPromocional: item.CODIGO || "",
				NumeroTarjeta: item.BINES || "",
			};
		});

		this.updateFormRows(dataParsed);
		// this.codigosBinesImport = dataParsed;
	}

	manageApi(mode) {
		this.loading = true;

		let codigoPromocionalBin: BodyInsertCodigoPromocionalBines = {
			pkId: "",
			Bines: [],
			CentrosIds: this.gsCentros.obtenercentrosAsociadosconOrden(
				this.centrosAsignados
			),
		};

		this.InsertCodigoPromocionalBin(codigoPromocionalBin);
	}

	InsertCodigoPromocionalBin(
		codigoPromocional: BodyInsertCodigoPromocionalBines
	) {
		this.codigoPromocionalBinesService
			.insertCodigoPromocionalBin(codigoPromocional)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(response) => {
					this.nextWindow(response);
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
					this.loading = false;
				}
			);
	}

	destroy() {
		this.panelService.setDiscardChanges(true);
		this.panelService.setClosePanel(null);
	}

	nextWindow(response: any) {
		if (response.mensajes.length > 0) {
			response.mensajes.forEach((element) => {
				this.snackvar_service.openSnackBar(
					element.descripcionMensaje,
					"",
					"red-snackbar",
					element.codigoMensaje,
					4000
				);
			});
		} else {
			this.snackvar_service.openSnackBar(
				this.translation["MensajeSuccessSnackBar"],
				"",
				"green-snackbar"
			);

			this.save();
			this.destroy();
		}
	}

	save() {
		this.loading = false;
		this.panelService.setSavePanel(null);
	}
}
