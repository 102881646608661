import { Component, Input, LOCALE_ID, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { Festivo } from "@modelosV6/festivos-Respuesta.model";
import { AppDateAdapter } from "@sharedV11/classes/dataAdapter/date-format.adapter";
import { IdiomaGR } from "@sharedV11/classes/recintos/grupo-recintos";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EditDiasdeCierreService } from "@sharedV11/services/components/festivos/festivos";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { getLocale } from "@sharedV11/utils/factories";
import { DualListComponent } from "angular-dual-listbox";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "DD-MM-YYYY",
    dateA11yLabel: "DD-MM-YYYY",
    monthYearA11yLabel: "DD-MM-YYYY",
  },
};

@Component({
  selector: "app-edit-diascierre",
  templateUrl: "./edit-diascierre.component.html",
  styleUrls: ["./edit-diascierre.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },
    {
      provide: LOCALE_ID,
      useFactory: getLocale,
      deps: [Translator],
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: getLocale,
      deps: [Translator],
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EditDiascierreComponent implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  @ViewChild("dpdesde") datePicker_dpdesde: MatDatepicker<Date>;
  @ViewChild("dphasta") datePicker_dphasta: MatDatepicker<Date>;

  translation: any;

  editRegistroForm: FormGroup;

  Festivo: Festivo;
  editorListaIdiomas: Array<IdiomaGR> = [];
  idiomasApp: Array<IdiomaGR> = [];
  modeinterval: string = "0";
  diassemana = [];
  diames = 0;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  //centros

  constructor(
    private panelService: PanelService,
    private fb: FormBuilder,
    private translator: Translator,
    private servicioAPI: ConectarApiService,
    private httpErrorService: HttpErrorsService,
    private snackvar_service: SnackbarInfoService,
    public globales: GlobalService,
    private editDiasdeCierreService: EditDiasdeCierreService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
    this.manageForm(this.pkId, this.mode);
  }

  async initializeTranslate() {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
  }

  initializeFormWithoutData() {
    this.editRegistroForm = this.fb.group({
      pkId: [""],
      Motivo: ["", Validators.required],
      Venta: [""],
      Fecha: [""],
      FechaDesde: [""],
      FechaHasta: [""],
      HoraInicio1Venta: ["00:00"],
      HoraFin1Venta: ["23:59"],
      HoraInicio2Venta: ["00:00"],
      HoraFin2Venta: ["23:59"],
      HoraInicio1Reserva: ["00:00"],
      HoraFin1Reserva: ["23:59"],
      HoraInicio2Reserva: ["00:00"],
      HoraFin2Reserva: ["23:59"],
      Reserva: [""],
      CentrosIds: [""],
    });
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.Festivo = this.editRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.getIdiomas([], this.mode);
      this.valuedChanged();
    }
    if (pkId != null) {
      this.findFestivo(pkId);
    }
  }

  findFestivo(pkId) {
    this.editDiasdeCierreService
      .findFestivoByPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (TiporeservaApi) => {
          // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
          let Festivo: Festivo =
            TiporeservaApi["DatosResult"]["listaFestivos"][0];

          if (Festivo != null) {
            this.changeValueForm(Festivo);
            this.Festivo = this.editRegistroForm.value;

            this.Festivo.HoraInicio1Venta = Festivo.HoraInicio1Venta.trim();
            this.Festivo.HoraFin1Venta = Festivo.HoraFin1Venta.trim();
            this.Festivo.HoraInicio2Venta = Festivo.HoraInicio2Venta.trim();
            this.Festivo.HoraFin2Venta = Festivo.HoraFin2Venta.trim();
            this.Festivo.HoraInicio1Reserva = Festivo.HoraInicio1Reserva.trim();
            this.Festivo.HoraFin1Reserva = Festivo.HoraFin1Reserva.trim();
            this.Festivo.HoraInicio2Reserva = Festivo.HoraInicio2Reserva.trim();
            this.Festivo.HoraFin2Reserva = Festivo.HoraFin2Reserva.trim();

            /*         if (this.mode=="D") {
        this.Festivo.FechaDesde = Festivo.Fecha;
        this.Festivo.FechaHasta = Festivo.Fecha;
        } */

            this.Festivo.CentrosIds = Festivo.CentrosIds;
            this.valuedChanged();
            this.getIdiomas(Festivo.Idiomas, this.mode);
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados = this.gsCentros.daTiposCentros(this.Festivo);

            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  changeValueForm(Festivo: Festivo) {
    this.editRegistroForm.get("pkId").setValue(Festivo.pkId);

    this.editRegistroForm.get("Motivo").setValue(Festivo.Motivo);
    this.editRegistroForm
      .get("Fecha")
      .setValue(this.globales.aFecha(Festivo.Fecha));

    //this.editRegistroForm.get("FechaHasta").setValue(this.globales.aFecha(Festivo.FechaHasta));

    if (moment(Festivo.HoraInicio1Venta).isValid()) {
      this.editRegistroForm
        .get("HoraInicio1Venta")
        .setValue(moment(Festivo.HoraInicio1Venta).format("HH:mm"));
    } else {
      if (Festivo.HoraInicio1Venta == "") Festivo.HoraInicio1Venta = "00:00";
      this.editRegistroForm
        .get("HoraInicio1Venta")
        .setValue(Festivo.HoraInicio1Venta);
    }

    if (moment(Festivo.HoraFin1Venta).isValid()) {
      this.editRegistroForm
        .get("HoraFin1Venta")
        .setValue(moment(Festivo.HoraFin1Venta).format("HH:mm"));
    } else {
      if (Festivo.HoraFin1Venta == "") Festivo.HoraFin1Venta = "23:59";
      this.editRegistroForm
        .get("HoraFin1Venta")
        .setValue(Festivo.HoraFin1Venta);
    }

    if (moment(Festivo.HoraInicio2Venta).isValid()) {
      this.editRegistroForm
        .get("HoraInicio2Venta")
        .setValue(moment(Festivo.HoraInicio2Venta).format("HH:mm"));
    } else {
      if (Festivo.HoraInicio2Venta == "") Festivo.HoraInicio2Venta = "00:00";
      this.editRegistroForm
        .get("HoraInicio2Venta")
        .setValue(Festivo.HoraInicio2Venta);
    }

    if (moment(Festivo.HoraFin2Venta).isValid()) {
      this.editRegistroForm
        .get("HoraFin2Venta")
        .setValue(moment(Festivo.HoraFin2Venta).format("HH:mm"));
    } else {
      if (Festivo.HoraFin2Venta == "") Festivo.HoraFin2Venta = "23:59";
      this.editRegistroForm
        .get("HoraFin2Venta")
        .setValue(Festivo.HoraFin2Venta);
    }

    /////

    if (moment(Festivo.HoraInicio1Reserva).isValid()) {
      this.editRegistroForm
        .get("HoraInicio1Reserva")
        .setValue(moment(Festivo.HoraInicio1Reserva).format("HH:mm"));
    } else {
      if (Festivo.HoraInicio1Reserva == "")
        Festivo.HoraInicio1Reserva = "00:00";
      this.editRegistroForm
        .get("HoraInicio1Reserva")
        .setValue(Festivo.HoraInicio1Reserva);
    }

    if (moment(Festivo.HoraFin1Reserva).isValid()) {
      this.editRegistroForm
        .get("HoraFin1Reserva")
        .setValue(moment(Festivo.HoraFin1Reserva).format("HH:mm"));
    } else {
      if (Festivo.HoraFin1Reserva == "") Festivo.HoraFin1Reserva = "23:59";
      this.editRegistroForm
        .get("HoraFin1Reserva")
        .setValue(Festivo.HoraFin1Reserva);
    }

    if (moment(Festivo.HoraInicio2Reserva).isValid()) {
      this.editRegistroForm
        .get("HoraInicio2Reserva")
        .setValue(moment(Festivo.HoraInicio2Reserva).format("HH:mm"));
    } else {
      if (Festivo.HoraInicio2Reserva == "")
        Festivo.HoraInicio2Reserva = "00:00";
      this.editRegistroForm
        .get("HoraInicio2Reserva")
        .setValue(Festivo.HoraInicio2Reserva);
    }

    if (moment(Festivo.HoraFin2Reserva).isValid()) {
      this.editRegistroForm
        .get("HoraFin2Reserva")
        .setValue(moment(Festivo.HoraFin2Reserva).format("HH:mm"));
    } else {
      if (Festivo.HoraFin2Reserva == "") Festivo.HoraFin2Reserva = "23:59";
      this.editRegistroForm
        .get("HoraFin2Reserva")
        .setValue(Festivo.HoraFin2Reserva);
    }

    this.editRegistroForm
      .get("Venta")
      .setValue(Festivo.Venta == "1" ? true : false);
    this.editRegistroForm
      .get("Reserva")
      .setValue(Festivo.Reserva == "1" ? true : false);

    if (this.mode == "D") {
      this.editRegistroForm
        .get("FechaDesde")
        .setValue(this.globales.aFecha(Festivo.Fecha));
      this.editRegistroForm
        .get("FechaHasta")
        .setValue(this.globales.aFecha(Festivo.Fecha));
    }
  }

  getIdiomas(idiomasgrupo, mode) {
    this.servicioAPI.getIdiomas().subscribe(
      (losidiomas) => {
        losidiomas.DatosResult.ListaIdiomas.map((idioma) => {
          let idiomaselect = new IdiomaGR();
          idiomaselect.CodIdioma = idioma.CodIdioma;
          idiomaselect.Nombre = idioma.Nombre;
          this.idiomasApp.push(idiomaselect);

          let idiomaObj = idiomasgrupo.find(
            (i) => i.CodIdioma == idioma.CodIdioma
          );

          this.editorListaIdiomas.push(idioma);
          this.editRegistroForm.addControl(
            "nombre" + idioma.CodIdioma,
            new FormControl(idiomaObj ? idiomaObj.Nombre : "")
          );
          this.editRegistroForm.addControl(
            "descripcion" + idioma.CodIdioma,
            new FormControl(idiomaObj ? idiomaObj.Descripcion : "")
          );
        });

        this.Festivo = this.editRegistroForm.value;
        this.valuedChanged();
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  cambiaNombre(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Nombre = evento;
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Descripcion =
      evento;
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe((value) => {
      if (this.Festivo != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.Festivo)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  discard() {
    this.changeValueForm(this.Festivo);
  }

  manageApi(Festivo: Festivo, mode) {
    if (this.editRegistroForm.controls["Venta"].value == true)
      Festivo.Venta = "1";
    else Festivo.Venta = "0";

    if (this.editRegistroForm.controls["Reserva"].value == true)
      Festivo.Reserva = "1";
    else Festivo.Reserva = "0";

    this.loading = true;
    switch (mode) {
      case "I":
        //insert
        Festivo.pkId = "0";
        this.IntervalUpdate(Festivo, "I");
        break;
      case "U":
        //update
        this.InsUpTiporeserva(Festivo, "U");

        break;
      case "D":
        //duplicate
        Festivo.pkId = "0";
        this.IntervalUpdate(Festivo, "I");

        break;

      default:
        break;
    }
  }

  DelTiporeserva(TPV) {
    /*   this.editTiposReservasService.updCategoria(TPV, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     }); */
  }

  getValues(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }) {}

  IntervalUpdate(Festivo, mode) {
    let di = this.datePicker_dpdesde.datepickerInput["value"].toDate();
    let df = this.datePicker_dphasta.datepickerInput["value"].toDate();

    //var now = new Date();
    var daysOfYear = [];
    for (var d = di; d <= df; d.setDate(d.getDate() + 1)) {
      let dd = new Date(d);
      switch (this.modeinterval) {
        case "0":
          daysOfYear.push(dd);
          break;
        case "1":
          let ds = dd.getDay().toString(); //dia de la semana
          // si ds esta en dias de la semana daysOfYear.push(dd);
          if (this.diassemana.includes(ds)) daysOfYear.push(dd);
          break;
        case "2":
          let dm = dd.getDate(); //dia de la semana
          if (dm == this.diames) daysOfYear.push(dd);
          break;
        default:
          break;
      }
    }

    daysOfYear.forEach((doy) => {
      Festivo.Fecha = moment(doy, "DD/MM/YYYY").format("DD/MM/YYYY");
      this.InsUpTiporeserva(Festivo, mode);
    });
  }

  InsUpTiporeserva(Festivo, mode) {
    this.editDiasdeCierreService
      .insUpTipoReserva(Festivo, mode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  submit() {
    this.editRegistroForm.markAllAsTouched();
    let Festivo: Festivo = this.editRegistroForm.value;
    Festivo.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );
    Festivo.Idiomas = [];
    this.editorListaIdiomas.forEach((i) => {
      let enIdioma: IdiomaGR = new IdiomaGR();
      enIdioma.CodIdioma = i.CodIdioma;
      enIdioma.Nombre = Festivo["nombre" + i.CodIdioma];
      enIdioma.Descripcion = Festivo["descripcion" + i.CodIdioma];
      Festivo.Idiomas.push(enIdioma);
    });
    if (this.editRegistroForm.valid) {
      this.manageApi(Festivo, this.mode);
    }
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if (fc_pv) {
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }

    return "Error";
  }

  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      console.table(response.Mensajes);
      response.Mensajes.forEach((element) => {
        this.snackvar_service.openSnackBar(
          element.DescripcionMensaje,
          "",
          "red-snackbar",
          element.CodigoMensaje
        );
      });
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["MensajeSuccessSnackBar"],
        "",
        "green-snackbar"
      );
    }
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }
}
