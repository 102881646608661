import {
  ChangeDetectorRef,
  Component,
  Input,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
  APP_FORMATOS_FECHA,
  AppDateAdapter,
} from "@otrosProveedoresV6/date-format.adapter";
import { MatPaginatorIntlEses } from "@otrosProveedoresV6/ES-es.paginator";
import { CodigoPromocional } from "@sharedV11/classes/CodigoPromociones/CodigoPromocional";
import {
  tipoCodigoPromociones,
  tipoEntradaPlantilla,
  tipoPromoEntradaPlantilla,
} from "@sharedV11/classes/tipoCodigoPromociones/tipoCodigoPromociones";
import { TableInteractiveComponent } from "@sharedV11/components/tables/table-interactive/table-interactive.component";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EditCodigoPromocionesService } from "@sharedV11/services/components/edit-codigo-promociones/edit-codigo-promociones.service";
import { EditTipoCodigoPromocionesService } from "@sharedV11/services/components/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { getLocale } from "@sharedV11/utils/factories";
import { DualListComponent } from "angular-dual-listbox";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-codigo-promocional",
  templateUrl: "./edit-codigo-promocional.component.html",
  styleUrls: ["./edit-codigo-promocional.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_FORMATOS_FECHA,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    {
      provide: LOCALE_ID,
      useFactory: getLocale,
      deps: [Translator],
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: getLocale,
      deps: [Translator],
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EditCodigoPromocionalComponent implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  @ViewChild("ti_tiposEntrada") ti_tiposEntrada: TableInteractiveComponent;
  @ViewChild("ti_tiposPromociones")
  ti_tiposPromociones: TableInteractiveComponent;

  tiposEntrada: any;
  translation: any;
  elementosBorrados: string[] = [];
  tiposBonoEntrada: tipoEntradaPlantilla[];
  promocionEntradaFormGroup: FormArray = this.formBuilder.array([]);
  columnsToDisplayTarifaEntrada: string[];

  editorRegistroForm: FormGroup;
  itemsTipoCodigoPromociones;

  dataSourceTarifaPromocionEntrada: MatTableDataSource<tipoEntradaPlantilla>;
  ///////
  tiposBonoEntrada1: tipoPromoEntradaPlantilla[];
  tiposEntrada1: any;
  //translation: any;
  elementosBorrados1: string[] = [];
  promocionEntradaFormGroup1: FormArray = this.formBuilder.array([]);
  columnsToDisplayTarifaEntrada1: string[];

  editorRegistroForm1: FormGroup;

  dataSourceTarifaPromocionEntrada1: MatTableDataSource<tipoEntradaPlantilla>;
  ///////
  editRegistroForm: FormGroup;

  tablavalida = true;
  tablavalida1 = true;

  CodigoPromocional: CodigoPromocional;
  tiposCodigosPromocionales = [];
  tiposventa: any;
  clases: any[];
  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  centrosasociados: string[] = [];
  tipoCentros: any[] = [];
  CentrosAPI: any;
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };
  tiposDivisas: any;

  LogitudPorDefecto: string;
  CodigoNoAleatorio: string;
  calcopkId: any;

  constructor(
    private editCodigoPromocionesService: EditCodigoPromocionesService,
    private editTipoCodigoPromocionesService: EditTipoCodigoPromocionesService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private formBuilder: FormBuilder,
    private gsCentros: GlobalServicCentros,
    public global: GlobalService,
    private servicioAPI: ConectarApiService
  ) {}

  getNombreGrupoById(id: string) {
    // TODO: falta acceso a la tabla tbGrupoTipoBono.PkId
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.CodigoNoAleatorio = localStorage.getItem("CodigoNoAleatorio");
    this.LogitudPorDefecto = localStorage.getItem("LogitudPorDefecto");

    if (this.CodigoNoAleatorio === "undefined") this.CodigoNoAleatorio = "1";
    if (this.LogitudPorDefecto === "undefined") this.LogitudPorDefecto = "12";

    this.tiposventa = this.global.tiposVenta;
    this.clases = this.global.clasesCodigo;
    this.loadCentros();
    this.cargarDatos();

    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode);

    // this.pkId="4"

    this.columnsToDisplayTarifaEntrada = [
      "Acciones",
      "NombreTipoEntrada",
      "iNumEntradas",
    ];

    this.columnsToDisplayTarifaEntrada1 = [
      "Acciones",
      "NombreTipoEntrada",
      "iNumEntradas",
    ];
    /*  this.tiposEntrada = {"DatosResult":{"tipoCodigoPromo":[{
      pkId: "1",
            Nombre: "ejemplo",
            chNombre: "ejemplo1",
            chDescripcion: "Descripción...",
            Descripcion: "Descripción...",
            bDeBaja: 0,
            iCodigoCliente: 0
    }]}}; */
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  cargarDatos() {
    //Cargamos Datos
    let resultado: boolean = false;

    this.servicioAPI.getDivisas().subscribe((divisas) => {
      if (divisas.DatosResult && divisas.DatosResult.ListaDivisas.length > 0) {
        this.tiposDivisas = divisas.DatosResult.ListaDivisas;
      } else {
        this.tiposDivisas = [];
        resultado = false;
      }
    });

    this.editTipoCodigoPromocionesService
      .getTiposCodigoPromocional()
      .subscribe((data) => {
        this.tiposCodigosPromocionales =
          data["DatosResult"]["ListaCodigoPromocional"];
      });

    //centros en cargar datos
    /*     this.servicioAPI.getCentros().subscribe(data => {
      this.CentrosAPI = data;
      this.tipoCentros = [];
      if (this.CentrosAPI.DatosResult != null) {
        if (this.CentrosAPI.DatosResult.ListaCentros != null) {
          this.tipoCentros = this.CentrosAPI.DatosResult.ListaCentros;
          this.centrosSinAsignar = this.filtrarTiposCentros();
          this.centrosAsignados = this.daTiposCentros();

        }
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    ); */
    //centros

    return resultado;
  }

  //centros
  /*     filtrarTiposCentros() {
      // return this.tipoCentros.filter(x => x.Activo == "True");
      let respuesta = [];
      let Centros = this.tipoCentros.filter(x => x.Activo == "True");
      let tmpCentrosAsociados = this.centrosasociados;
      if (Centros != null && Centros.length > 0) {
        respuesta = Centros.filter(function (item) {
          return tmpCentrosAsociados.findIndex(function (itemSub) { return itemSub == item.pkId }) === -1;
        });
      }
      else {
        respuesta = this.tipoCentros.filter(x => x.Activo == "True");
      }
      return respuesta;
    } */

  async initializeTranslate() {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
  }

  initializeFormWithoutData() {
    this.editRegistroForm = this.fb.group({
      pkId: [""],
      Habilitado: [false, Validators.required],
      TipoCodigoPromocionalId: [""],
      //TipoDeCodigo: [""],
      Codigo: ["", [Validators.pattern(/^[0-9a-zA-Z]+$/)]],
      Clase: ["", Validators.required],
      TipoVenta: ["", Validators.required],
      Cantidad: ["", Validators.required],
      DivisaId: [""],
      CodigoLongitud: [parseInt(this.LogitudPorDefecto)],
      FechaInicio: ["", Validators.required],
      FechaActivoInicio: ["", Validators.required],
      FechaFin: ["", Validators.required],
      FechaActivoFin: ["", Validators.required],
    });
  }
  manageForm(pkId, mode) {
    if (mode == "I") {
      //this.itemsTipoCodigoPromociones = this.editRegistroForm.value;
      this.CodigoPromocional = this.editRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(
        this.CodigoPromocional
      );
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      this.findCodigoPromocional(pkId);
    }
  }

  findCodigoPromocional(pkId) {
    this.editCodigoPromocionesService
      .GetCodigoPromocional(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
          let CodigoPromocional =
            data["DatosResult"]["ListaCodigoPromocional"][0];
          this.CodigoPromocional = CodigoPromocional;
          if (CodigoPromocional != null) {
            this.changeValueForm(CodigoPromocional);
            this.CodigoPromocional = this.editRegistroForm.value;
            this.valuedChanged();
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados =
              this.gsCentros.daTiposCentros(CodigoPromocional);
            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  changeValueForm(CodigoPromocional: CodigoPromocional) {

    const isDuplicated: boolean = this.mode === "D";

    this.editRegistroForm.get("pkId").setValue(isDuplicated ? 0 : CodigoPromocional.pkId);
    this.editRegistroForm
      .get("Habilitado")
      .setValue(CodigoPromocional.Habilitado == 1 ? true : false);
    this.editRegistroForm
      .get("TipoCodigoPromocionalId")
      .setValue(CodigoPromocional.TipoCodigoPromocionalId);
    this.editRegistroForm.get("Codigo").setValue(isDuplicated ? "" : CodigoPromocional.Codigo);
    this.editRegistroForm.get("Clase").setValue(CodigoPromocional.Clase);
    this.editRegistroForm
      .get("TipoVenta")
      .setValue(CodigoPromocional.TipoVenta);
    this.editRegistroForm.get("Cantidad").setValue(CodigoPromocional.Cantidad);
    this.editRegistroForm.get("DivisaId").setValue(CodigoPromocional.DivisaId);
    this.editRegistroForm
      .get("CodigoLongitud")
      .setValue(CodigoPromocional.CodigoLongitud);
    this.editRegistroForm
      .get("FechaInicio")
      .setValue(moment(CodigoPromocional.FechaInicio, "YYYY/MM/DD").toDate());
    this.editRegistroForm
      .get("FechaActivoInicio")
      .setValue(
        moment(CodigoPromocional.FechaActivoInicio, "YYYY/MM/DD").toDate()
      );
    this.editRegistroForm
      .get("FechaFin")
      .setValue(moment(CodigoPromocional.FechaFin, "YYYY/MM/DD").toDate());
    this.editRegistroForm
      .get("FechaActivoFin")
      .setValue(
        moment(CodigoPromocional.FechaActivoFin, "YYYY/MM/DD").toDate()
      );
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe((value) => {
      if (this.CodigoPromocional != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.CodigoPromocional)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }
  /*   submit() {

     this.editRegistroForm.markAllAsTouched()
    let tipoCodigoPromociones: tipoCodigoPromociones = this.editRegistroForm.value;
    if (this.editRegistroForm.valid) {
      this.manageApi(tipoCodigoPromociones, this.mode)
    } 

  } */

  submit(cerrarpanel: boolean = true) {
    this.editRegistroForm.markAllAsTouched();
    if (
      this.ti_tiposEntrada.dataSource.data.length > 0 ||
      this.ti_tiposPromociones.dataSource.data.length > 0
    ) {

      console.log({
        tiposEntrada: this.ti_tiposEntrada.dataSource.data,
        tiposPromociones: this.ti_tiposPromociones.dataSource.data,
      })

      const valuesToParseTipoEntradaPromociones = [
        "BaseImponible",
        "Comision",
        "ComisionBI",
        "IVA",
        "IvaComision",
        "Precio"
      ];

      let tiposEntrada: any[] = this.ti_tiposEntrada.dataSource.data;

      let tiposPromociones: any[] = this.ti_tiposPromociones.dataSource.data;

      if (tiposEntrada.length > 0) {
        tiposEntrada.forEach((tipoEntrada) => {
          valuesToParseTipoEntradaPromociones.forEach((value) => {
            tipoEntrada[value] = tipoEntrada[value]
              ? tipoEntrada[value].replace(",", ".")
              : 0;
          });
        });
      }

      if (tiposPromociones.length > 0) {
        tiposPromociones.forEach((tipoPromocion) => {
          valuesToParseTipoEntradaPromociones.forEach((value) => {
            tipoPromocion[value] = tipoPromocion[value]
              ? tipoPromocion[value].replace(",", ".")
              : 0;
          });
        });
      }

      this.editRegistroForm.markAllAsTouched();
      let tipoCodigoPromociones: tipoCodigoPromociones = this.editRegistroForm.value;
      tipoCodigoPromociones["CodigoPromocionalEntradas"] = tiposEntrada;

      tipoCodigoPromociones["CodigoPromocionalEntradas"].forEach((cp) => {
        cp["DeBaja"] = cp["DeBaja"] == true || cp["DeBaja"] == "1" ? "1" : "0";
      });

      if (this.mode === "I") {
        tipoCodigoPromociones["CodigoPromocionalEntradas"].forEach((cp) => {
          cp["TipoCodigoPromocionalEntradaId"] = cp["pkId"];
        });
      }

      tipoCodigoPromociones["CodigoPromocionalTiposBono"] = tiposPromociones;
      //let sublista = this.ti_tiposPromociones.ti_subtabla.dataSource.data;
      var sublista = [];

      this.ti_tiposPromociones.ti_subtables.forEach((list) => {
        if (list.dataSource.data.length > 0)
          sublista.push({
            CodigoPromocionalTipoBonoId:
              list.dataSource.data[0].CodigoPromocionalTipoBonoId,
            lista: list.dataSource.data.map((el) => {
              const keys = Object.keys(el);
              for (const key of keys) {
                if (valuesToParseTipoEntradaPromociones.includes(key)) {
                  el[key] = el[key] ? el[key].replace(",", ".") : 0;
                }
              }
              return el;
            }),
          });
      });

      tipoCodigoPromociones["CodigoPromocionalTiposBono"].forEach((cp) => {
        let tmp = sublista.find(
          (el) => el.CodigoPromocionalTipoBonoId == cp.pkId
        );
        
        cp["CodigoPromocionalTiposBonoEntradas"] = tmp ? tmp.lista : [];
      });

      if (this.editRegistroForm.valid) {
        this.manageApi(tipoCodigoPromociones, this.mode, cerrarpanel);
      } else {
        this.snackvar_service.openSnackBar("Faltan datos", "", "red-snackbar");
      }
    } else {
      this.snackvar_service.openSnackBar(
        "Es necesario añadir un producto",
        "",
        "red-snackbar"
      );
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save(cerrarpanel?) {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(cerrarpanel);
  }

  discard() {
    this.changeValueForm(this.CodigoPromocional);
  }
  manageApi(codigoPromocional, mode, cerrarpanel?) {
    this.loading = true;

    codigoPromocional.FechaInicio = moment(
      codigoPromocional.FechaInicio,
      "DD/MM/YYYY"
    ).format("YYYY/MM/DD");
    codigoPromocional.FechaActivoInicio = moment(
      codigoPromocional.FechaActivoInicio,
      "DD/MM/YYYY"
    ).format("YYYY/MM/DD");
    codigoPromocional.FechaFin = moment(
      codigoPromocional.FechaFin,
      "DD/MM/YYYY"
    ).format("YYYY/MM/DD");
    codigoPromocional.FechaActivoFin = moment(
      codigoPromocional.FechaActivoFin,
      "DD/MM/YYYY"
    ).format("YYYY/MM/DD");

    codigoPromocional.Habilitado = codigoPromocional.Habilitado ? "1" : "0";

    /*     codigoPromocional.CodigoPromocionalEntradas = [];
    codigoPromocional.CodigoPromocionalTiposBono = []; */

    codigoPromocional.CentrosIds =
      this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);

    codigoPromocional.Cantidad = codigoPromocional.Cantidad.toString();

    /*     if (ti_tiposEntrada.CodigoPromocionalEntradas) ti_tiposEntrada.TipoCodigoPromocionalEntradas.forEach( e => {
      e.DeBaja = (e.DeBaja == true) ? "1" : "0";
    }); */

    switch (mode) {
      case "I":
        //insert
        codigoPromocional.pkId = "0";
        this.InsCodigoPromocional(codigoPromocional, cerrarpanel);
        break;
      case "U":
        //update
        this.UpdCodigoPromocional(codigoPromocional, cerrarpanel);

        break;
      case "D":
        //duplicate
        codigoPromocional.pkId = "0";
        this.InsCodigoPromocional(codigoPromocional, cerrarpanel);
        break;

      default:
        break;
    }
  }

  UpdCodigoPromocional(codigoPromocional, cerrarpanel?) {
    this.editCodigoPromocionesService
      .updCodigoPromocional(codigoPromocional, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response, cerrarpanel);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  InsCodigoPromocional(codigoPromocional, cerrarpanel?) {
    this.editCodigoPromocionesService
      .insCodigoPromocional(codigoPromocional, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response, cerrarpanel);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  nextWindow(response: any, cerrarpanel?) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save(cerrarpanel);
      if (cerrarpanel && cerrarpanel == true) this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach((element) => {
          this.snackvar_service.openSnackBar(
            element.DescripcionMensaje,
            "",
            "red-snackbar",
            element.CodigoMensaje
          );
        });
      } else {
        this.snackvar_service.openSnackBar(
          this.translation["MensajeSuccessSnackBar"],
          "",
          "green-snackbar"
        );
      }
    }
  }

  existErrors() {
    //return (this.editRegistroForm.get("pkId").errors || this.editRegistroForm.get("Nombre").errors || this.editRegistroForm.get("Descripcion").errors || this.editRegistroForm.get("Cliente").errors) ? true : false;
    return (
      this.editRegistroForm.get("TipoCodigoPromocionalId").errors ||
      this.editRegistroForm.get("Cantidad").errors
    );
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if (fc_pv) {
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }

    return "Error";
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }
  
  closeInputFilter(enventValues) {
    //this.insertDataInForm()

    this.editRegistroForm.get(enventValues.name).setValue(enventValues.value);

    // this.f[enventValues.name].value == enventValues.value ? this.panelService.setDiscardChanges(true):this.panelService.setDiscardChanges(false)
  }

  async loadInitTables(pkId) {
    let entradas = [];
    let tiposbonos = [];
    await this.editTipoCodigoPromocionesService
      .getTiposCodigoPromocionalByPkIdAsync(pkId)
      .then((tp) => {
        entradas =
          tp.DatosResult.ListaCodigoPromocional[0]
            .TipoCodigoPromocionalEntradas;
        tiposbonos =
          tp.DatosResult.ListaCodigoPromocional[0]
            .TipoCodigoPromocionalTiposBono;
        /* var entradasrow = [];
      entradas.forEach(element => {
        let linea = {"pkId":"","Cantidad":"0","TipoEntradaId":element.TipoEntradaId,"NombreTipoEntrada":element.NombreTipoEntrada}
        entradasrow.push(linea);
      });
      this.ti_tiposEntrada.dataSource = new MatTableDataSource(entradasrow);
      this.ti_tiposEntrada.dataSource['totalResultados'] = entradasrow.length;
      this.ti_tiposEntrada.loadData(this.ti_tiposEntrada.dataSource);
  
      var bonosrow = [];
      tiposbonos.forEach(element => {
        let linea = {"pkId":"","Cantidad":"0","TipoBonoId":element.TipoBonoId,"DescripcionTipoBono":element.DescripcionTipoBono}
        bonosrow.push(linea);
      });
      this.ti_tiposPromociones.dataSource = new MatTableDataSource(bonosrow);
      this.ti_tiposPromociones.dataSource['totalResultados'] = bonosrow.length;
      this.ti_tiposPromociones.loadData(this.ti_tiposEntrada.dataSource); */
        return { entradas: entradas, tiposbonos: tiposbonos };
      });
  }

  closeInputFilterTipo(enventValues) {
    this.editRegistroForm.get(enventValues.name).setValue(enventValues.value);
    this.calcopkId = enventValues.value;
    this.cdRef.detectChanges();

    this.ti_tiposEntrada.setInitCalco();
    this.ti_tiposEntrada.reloadTable();

    this.ti_tiposPromociones.setInitCalco();
    this.ti_tiposPromociones.reloadTable();

    /*     var sublista=[];
    this.ti_tiposPromociones.ti_subtables.forEach( list => {
      if (list.dataSource.data.length>0) 
        sublista.push({"CodigoPromocionalTipoBonoId":list.dataSource.data[0].CodigoPromocionalTipoBonoId,"lista":list.dataSource.data});
    }); */
    /*     tipoCodigoPromociones["CodigoPromocionalTiposBono"].forEach(cp => {
      let tmp = sublista.find( el => el.CodigoPromocionalTipoBonoId== cp.pkId);
      cp["CodigoPromocionalTiposBonoEntradas"]= tmp ?  tmp.lista: [];
    }); */

    /*     let initables = this.loadInitTables(enventValues.value);
    var entradasrow = [];
    initables["entradas"].forEach(element => {
      let linea = {"pkId":"","Cantidad":"0","TipoEntradaId":element.TipoEntradaId,"NombreTipoEntrada":element.NombreTipoEntrada}
      entradasrow.push(linea);
    });
    this.ti_tiposEntrada.dataSource = new MatTableDataSource(entradasrow);
    this.ti_tiposEntrada.dataSource['totalResultados'] = entradasrow.length;
    //this.ti_tiposEntrada.dataSource['pagelements'] = paginator.pagelements;
    this.ti_tiposEntrada.loadData(this.ti_tiposEntrada.dataSource);

    var bonosrow = [];
    initables["tiposbonos"].forEach(element => {
      let linea = {"pkId":"","Cantidad":"0","TipoBonoId":element.TipoBonoId,"DescripcionTipoBono":element.DescripcionTipoBono}
      bonosrow.push(linea);
    });
    this.ti_tiposPromociones.dataSource = new MatTableDataSource(bonosrow);
    this.ti_tiposPromociones.dataSource['totalResultados'] = bonosrow.length;
      //this.ti_tiposEntrada.dataSource['pagelements'] = paginator.pagelements;
    this.ti_tiposPromociones.loadData(this.ti_tiposEntrada.dataSource); */
  }
}
