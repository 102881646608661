import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Paginator } from "@sharedV11/classes/paginator/paginator";
import {
	DaysWeek,
	GrupoTarifaResponse,
	IGrupoTarifa,
	IUGrupoTarifaAPI,
	Mode,
} from "@sharedV11/classes/tarifas/grupostarifas";
import { Tiporeserva } from "@sharedV11/classes/tarifas/tiposreservas";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { IProductoTienda, PayloadInsertProducto, ProductoTiendaFormValue } from "@sharedV11/services/components/tienda/productos-tienda.interface";
import { TiendaProductosService } from "@sharedV11/services/components/tienda/productos.service";
import { EditTiposReservasService } from "@sharedV11/services/components/tiposreservas/edit-tiposreservas";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { isEqual } from "@sharedV11/utils/lodash";
import { DualListComponent } from "angular-dual-listbox";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-edit-producto",
	templateUrl: "./edit-producto.component.html",
	styleUrls: ["./edit-producto.component.scss"],
})
export class EditProductoComponent implements OnInit {
	loading = true;
	private ngUnsubscribe = new Subject();

	@Input() pkId: string;
	@Input() mode: Mode;
	@Input() modificar: boolean;

	translation: any;

	editRegistroForm: FormGroup;

	ProductoTienda: ProductoTiendaFormValue;

	/* Datos Form */
	gruposTipoEntrada: any[] = [];
	proveedoresTienda: any[] = [];
	categoriasJerarquicas = [];
	tiendaBotonera = [];
	tiposProductos = [];
	gruposAsociados = [];
	tiposVisita = [];
	tiposNiveles = [];
	tiposPublico = [];
	tiposVisitante = [];
	teclasRapidas = [];
	tiposEntrada = [];
	niveles = [];

	/* Toggles */
	showTogglesRestauracion = true;

	//Tarifas paginator
	paginator: Paginator = {
		page: 1,
		pagelements: 10,
		orderby: "pkId",
		ordertype: "ASC",
	};

	//centros
	centrosSinAsignar = [];
	centrosAsignados = [];
	tipoCentros: any[] = [];

	//centros
	format = {
		add: "Añadir",
		remove: "Borrar",
		all: "Todos",
		none: "Ninguno",
		direction: DualListComponent.LTR,
		draggable: true,
		locale: "es",
	};

	//Divisas
	NumeroDecimales: any;
	PosicionDivisa: any;
	SimboloDecimal: any;
	SimboloDerecha: any;
	simbolo: any;

	constructor(
		private panelService: PanelService,
		private fb: FormBuilder,
		private translator: Translator,
		private httpErrorService: HttpErrorsService,
		private snackvar_service: SnackbarInfoService,
		private editTiposReservasService: EditTiposReservasService,
		private gsCentros: GlobalServicCentros,
		private servicioAPI: ConectarApiService,
		private tiendaProductoService: TiendaProductosService
	) {}

	async ngOnInit() {
		try {
			await this.loadCentros()
			this.initializeTranslate();
			this.initializeFormWithoutData();
			this.getLocalStorage();

			const dataLoaded = await this.loadData();

			if (!dataLoaded) {
				this.loading = false;
				this.snackvar_service.openSnackBar(
					this.translation["ERROR_OBTENER_REGISTRO"],
					"",
					"red-snackbar"
				);
				return;
			}

			this.manageForm(this.pkId, this.mode);
		} catch (err) {
			console.error(err);
		}
	}

	async loadData(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			const observableGruposTiposEntrada =
				this.servicioAPI.getGruposTiposEntrada();
			const observableProveedorTienda =
				this.tiendaProductoService.listProveedoresTienda();
			const observableCategoriasJerarquicas =
				this.tiendaProductoService.listCategoriasJerarquicas();
			const observableTiendaBotonera =
				this.tiendaProductoService.listTiendaBotonera();
			const observableTiposProductos = this.servicioAPI.GetTiposProductos();
			const observableGruposAsociados = this.servicioAPI.GetGruposAsociados();
			const observableTiposVisita = this.servicioAPI.GetTiposVisita();
			const observableTiposNivel = this.servicioAPI.GetTiposNivel();
			const observableTiposPublico =
				this.servicioAPI.ObtenerCustomanTipoPublico();
			const observableTiposVisitante =
				this.servicioAPI.ObtenerCustomanTipoVisitante();

			const observableTiposEntrada = this.servicioAPI.GetTiposEntradaDatos();
			const observableNiveles = this.servicioAPI.ObtenerCustomanNivel();

			forkJoin([
				observableGruposTiposEntrada,
				observableProveedorTienda,
				observableCategoriasJerarquicas,
				observableTiendaBotonera,
				observableTiposProductos,
				observableGruposAsociados,
				observableTiposVisita,
				observableTiposNivel,
				observableTiposPublico,
				observableTiposVisitante,
				observableTiposEntrada,
				observableNiveles
			]).subscribe(
				([
					dataGruposTiposEntrada,
					dataProveedorTienda,
					dataCategoriasJerarquicas,
					dataTiendaBotonera,
					dataTiposProductos,
					dataGruposAsociados,
					dataTiposVisita,
					dataTiposNivel,
					dataTiposPublico,
					dataTiposVisitante,
					dataTiposEntrada,
					dataNiveles
				]) => {
					const hasDataGruposTiposEntrada =
						dataGruposTiposEntrada?.DatosResult &&
						dataGruposTiposEntrada?.DatosResult.customanGrupoTipoEntrada
							.length > 0;

					this.gruposTipoEntrada = hasDataGruposTiposEntrada
						? dataGruposTiposEntrada.DatosResult.customanGrupoTipoEntrada
						: [];

					const hasDataProveedorTienda =
						dataProveedorTienda?.datosResult &&
						dataProveedorTienda?.datosResult.listado.length > 0;

					this.proveedoresTienda = hasDataProveedorTienda
						? dataProveedorTienda.datosResult.listado
						: [];

					const hasDataCategoriasJerarquicas =
						dataCategoriasJerarquicas?.datosResult &&
						dataCategoriasJerarquicas?.datosResult.listado.length > 0;

					this.categoriasJerarquicas = hasDataCategoriasJerarquicas
						? dataCategoriasJerarquicas.datosResult.listado
						: [];

					const hasDataTiendaBotonera =
						dataTiendaBotonera?.datosResult &&
						dataTiendaBotonera?.datosResult.lista.length > 0;

					this.tiendaBotonera = hasDataTiendaBotonera
						? dataTiendaBotonera.datosResult.lista
						: [];

					const hasDataTiposProductos =
						dataTiposProductos?.DatosResult &&
						dataTiposProductos.DatosResult.ListadoTiposProductos.length > 0;

					this.tiposProductos = hasDataTiposProductos
						? dataTiposProductos.DatosResult.ListadoTiposProductos
						: [];

					const hasDataGruposAsociados =
						dataGruposAsociados?.DatosResult &&
						dataGruposAsociados?.DatosResult.ListadoGruposAsociados.length > 0;

					this.gruposAsociados = hasDataGruposAsociados
						? dataGruposAsociados.DatosResult.ListadoGruposAsociados
						: [];

					const hasDataTiposVisita =
						dataTiposVisita?.DatosResult &&
						dataTiposVisita?.DatosResult.lista.length > 0;

					this.tiposVisita = hasDataTiposVisita
						? dataTiposVisita.DatosResult.lista
						: [];

					const hasDataTiposNivel =
						dataTiposNivel.DatosResult &&
						dataTiposNivel.DatosResult.ListadoTiposNiveles.length > 0;

					this.tiposNiveles = hasDataTiposNivel
						? dataTiposNivel.DatosResult.ListadoTiposNiveles
						: [];

					const hasDataTiposPublico =
						dataTiposPublico.DatosResult &&
						dataTiposPublico.DatosResult.lista.length > 0;

					this.tiposPublico = hasDataTiposPublico
						? dataTiposPublico.DatosResult.lista
						: [];

					const hasDataTiposVisitante =
						dataTiposVisitante.DatosResult &&
						dataTiposVisitante.DatosResult.lista.length > 0;

					this.tiposVisitante = hasDataTiposVisitante
						? dataTiposVisitante.DatosResult.lista
						: [];

					const hasDataTiposEntrada = dataTiposEntrada.DatosResult && dataTiposEntrada.DatosResult.ListadoTiposEntradaDatos.length > 0;

					this.tiposEntrada = hasDataTiposEntrada
						? dataTiposEntrada.DatosResult.ListadoTiposEntradaDatos
						: [];
					
					const hasDataNiveles = dataNiveles?.DatosResult && dataNiveles.DatosResult?.lista.length > 0;

					this.niveles = hasDataNiveles
						? dataNiveles.DatosResult.lista
						: [];

					resolve(true);
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
					reject(false);
				}
			);
		});

		return promise;
	}

	async loadCentros() {
		await this.gsCentros.getCentros();
	}

	getLocalStorage() {
		// divisas from localstorage
		this.NumeroDecimales = localStorage.getItem("NumeroDecimales");
		this.PosicionDivisa = localStorage.getItem("PosicionDivisa");
		this.SimboloDecimal = localStorage.getItem("SimboloDecimal");
		this.SimboloDerecha = localStorage.getItem("SimboloDerecha");
		this.simbolo = localStorage.getItem("simbolo");
	}

	initializeTranslate() {
		this.translation = this.translator.GetTranslations();
		this.format = {
			add: this.translation.Anadir,
			remove: this.translation.Borrar,
			all: this.translation.Todos,
			none: this.translation.Ninguno,
			direction: DualListComponent.LTR,
			draggable: true,
			locale: "es",
		};
	}

	initializeFormWithoutData() {

		const divisaRefIf = localStorage.getItem("DivisaRefId") || "0";

		this.editRegistroForm = this.fb.group({
			pkId: [""],
			nombre: ["", Validators.required],
			descripcion: ["", [Validators.maxLength(500)]],
			aLaVenta: [false, Validators.required],
			barCode: ["", [Validators.maxLength(500)]],
			divisaId: [divisaRefIf, Validators.required],
			textoPantalla: ["", [Validators.maxLength(30)]],
			textoImpresion: ["", [Validators.maxLength(50)]],
			textoDisplay: ["", [Validators.maxLength(30)]],
			grupoId: ["", Validators.required],
			proveedorId: [""],
			categoriaJerarquicaId: ["", Validators.required],
			teclaRapida: [""],
			codigoBarras: [false],
			imprimirTicket: [false, Validators.required],
			imprimirPrecio: [false, Validators.required],
			impresionConjunta: [false],
			tieneNominacion: [false],
			teDatosId: [""],
			tipoProductoId: [""],
			grupoAsociadoId: [""],
			tipoVisitaId: [""],
			tipoNivelId: [""],
			tipoPublicoId: [""],
			tipoVisitanteId: [""],
			nivelesId: [""],
			centrosIds: [""],
			pkIdStock: [""],
		});
	}

	manageForm(pkId, mode) {

		this.validateShowHideToggles();

		if (mode == "I") {
			this.ProductoTienda = this.editRegistroForm.value;

			//centros
			this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
			this.centrosAsignados = [];

			this.loading = false;
			this.valuedChanged();
		}
		if (pkId != null) {
			this.findProductoTienda(pkId);
		}
	}

	validateShowHideToggles() {
		this.editRegistroForm.get("categoriaJerarquicaId").valueChanges.subscribe((value) => {
			
			console.log("categoriaJerarquicaId::", value);

			const categoriaPkid = value ? +value : "";
			
			if (!categoriaPkid) {
				this.showTogglesRestauracion = true;
				return;
			}

			const categoria = this.categoriasJerarquicas.find(
				(categoria) => categoria.pkId === categoriaPkid
			);

			if (!categoria) {
				this.showTogglesRestauracion = true;
				return;
			}

			this.showTogglesRestauracion = categoria?.tipoElemento == 2 ? false : true;

			if (!this.showTogglesRestauracion) {
				this.editRegistroForm.get("codigoBarras").setValue(false);
				this.editRegistroForm.get("imprimirTicket").setValue(false);
				this.editRegistroForm.get("imprimirPrecio").setValue(false);
				this.editRegistroForm.get("impresionConjunta").setValue(false);

				//Quitar validaciones
				this.editRegistroForm.get("codigoBarras").clearValidators();
				this.editRegistroForm.get("imprimirTicket").clearValidators();
				this.editRegistroForm.get("imprimirPrecio").clearValidators();
				this.editRegistroForm.get("impresionConjunta").clearValidators();
			}

			return;
		});
	}

	findProductoTienda(pkId) {
		this.tiendaProductoService
			.findProductoTiendaByPkId(pkId)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(data) => {

					if (!data) {
						this.loading = false;

						this.snackvar_service.openSnackBar(
							this.translation["ERROR_OBTENER_REGISTRO"],
							"",
							"red-snackbar"
						);

						return;
					}

					let productoTienda = data;

					if (productoTienda != null) {
						this.changeValueForm(productoTienda);
						this.ProductoTienda = this.editRegistroForm.value;
						this.ProductoTienda.centrosIds = productoTienda.tipoEntrada.centrosIds;

						this.valuedChanged();

						//centros
						this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
						this.centrosAsignados = this.gsCentros.daTiposCentros(
							this.ProductoTienda
						);

						this.loading = false;
					}
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}


	changeValueForm(ProductoTienda: IProductoTienda) {

		const { tipoEntrada, stock } = ProductoTienda;

		this.editRegistroForm.get("pkId").setValue(tipoEntrada.pkId);
		this.editRegistroForm.get("nombre").setValue(tipoEntrada.nombre);
		this.editRegistroForm.get("descripcion").setValue(tipoEntrada.descripcion);
		this.editRegistroForm.get("aLaVenta").setValue(tipoEntrada.aLaVenta);
		this.editRegistroForm.get("barCode").setValue(stock.barCode);
		this.editRegistroForm.get("divisaId").setValue(tipoEntrada.divisa);
		this.editRegistroForm.get("textoPantalla").setValue(tipoEntrada.textoPantalla);
		this.editRegistroForm
			.get("textoImpresion")
			.setValue(tipoEntrada.textoImpresion);
		this.editRegistroForm.get("textoDisplay").setValue(tipoEntrada.textoDisplay);
		this.editRegistroForm.get("grupoId").setValue(tipoEntrada.grupoId);
		this.editRegistroForm.get("proveedorId").setValue(stock.proveedorTiendaId);
		this.editRegistroForm
			.get("categoriaJerarquicaId")
			.setValue(stock.categoriaJerarquicaId);
	this.editRegistroForm.get("teclaRapida").setValue(tipoEntrada.teclaRapida);
	this.editRegistroForm.get("codigoBarras").setValue(stock.barCode ? true : false);
	this.editRegistroForm.get("imprimirTicket").setValue(tipoEntrada.imprimirTicket ? true : false);
	this.editRegistroForm.get("imprimirPrecio").setValue(tipoEntrada.imprimirPrecio ? true : false);

	//Valor no se encuentra en la API
	this.editRegistroForm.get("impresionConjunta").setValue(false);

	//Valor no se encuentra en la API
	this.editRegistroForm.get("tieneNominacion").setValue(false);

	this.editRegistroForm.get("teDatosId").setValue(tipoEntrada.teDatosId);
	this.editRegistroForm.get("tipoProductoId").setValue(tipoEntrada.tipoProductoId);
	this.editRegistroForm.get("grupoAsociadoId").setValue(tipoEntrada.grupoAsociadoId);
	this.editRegistroForm.get("tipoVisitaId").setValue(tipoEntrada.tipoVisitaId);
	this.editRegistroForm.get("tipoNivelId").setValue(tipoEntrada.tipoNivelId);
	this.editRegistroForm.get("tipoPublicoId").setValue(tipoEntrada.tipoPublicoId);
	this.editRegistroForm.get("tipoVisitanteId").setValue(tipoEntrada.tipoVisitanteId);
	this.editRegistroForm.get("nivelesId").setValue(tipoEntrada.nivelesId);
	this.editRegistroForm.get("centrosIds").setValue(tipoEntrada.centrosIds);
	this.editRegistroForm.get("pkIdStock").setValue(stock.pkId);
	}

	parseFormValueToProductoTienda(): IProductoTienda {
		const formValue: ProductoTiendaFormValue = this.editRegistroForm.value;

		return {
			tipoEntrada: {
				pkId: formValue.pkId,
				nombre: formValue.nombre,
				descripcion: formValue.descripcion,
				aLaVenta: formValue.aLaVenta,
				textoPantalla: formValue.textoPantalla,
				textoImpresion: formValue.textoImpresion,
				textoDisplay: formValue.textoDisplay,
				divisa: formValue.divisaId,
				grupoId: formValue.grupoId,
				grupoAsociadoId: formValue.grupoAsociadoId,
				tipoNivelId: formValue.tipoNivelId,
				tipoPublicoId: formValue.tipoPublicoId,
				tipoVisitanteId: formValue.tipoVisitanteId,
				tipoVisitaId: formValue.tipoVisitaId,
				nivelesId: formValue.nivelesId,
				teDatosId: formValue.teDatosId,
				tipoProductoId: formValue.tipoProductoId,
				centrosIds: formValue.centrosIds,
				teclaRapida: formValue.teclaRapida,
				imprimirPrecio: formValue.imprimirPrecio ? true : false,
				imprimirTicket: formValue.imprimirTicket ? true : false,

			},
			stock: {
				pkId: formValue.pkIdStock,
				tipoEntradaId: formValue.pkId,
				categoriaJerarquicaId: formValue.categoriaJerarquicaId,
				proveedorTiendaId: formValue.proveedorId,
				cantidadActual: 0,
				cantidadMinima: 0,
				barCode: formValue.barCode
			},
		};
	}


	generateProductoTiendaFormValue(): IProductoTienda {
		const formValue: ProductoTiendaFormValue = this.ProductoTienda;

		return {
			tipoEntrada: {
				pkId: formValue.pkId,
				nombre: formValue.nombre,
				descripcion: formValue.descripcion,
				aLaVenta: formValue.aLaVenta,
				textoPantalla: formValue.textoPantalla,
				textoImpresion: formValue.textoImpresion,
				textoDisplay: formValue.textoDisplay,
				divisa: formValue.divisaId,
				grupoId: formValue.grupoId,
				grupoAsociadoId: formValue.grupoAsociadoId,
				tipoNivelId: formValue.tipoNivelId,
				tipoPublicoId: formValue.tipoPublicoId,
				tipoVisitanteId: formValue.tipoVisitanteId,
				tipoVisitaId: formValue.tipoVisitaId,
				nivelesId: formValue.nivelesId,
				teDatosId: formValue.teDatosId,
				tipoProductoId: formValue.tipoProductoId,
				centrosIds: formValue.centrosIds,
				teclaRapida: formValue.teclaRapida,
				imprimirPrecio: formValue.imprimirPrecio ? true : false,
				imprimirTicket: formValue.imprimirTicket ? true : false,

			},
			stock: {
				pkId: formValue.pkIdStock,
				tipoEntradaId: formValue.pkId,
				categoriaJerarquicaId: formValue.categoriaJerarquicaId,
				proveedorTiendaId: formValue.proveedorId,
				cantidadActual: 0,
				cantidadMinima: 0,
				barCode: formValue.barCode
			},
		};
	}

	generatePayloadInsUpProductoTienda(): PayloadInsertProducto {
		const productoTiendaValue = this.parseFormValueToProductoTienda();

		const payload: PayloadInsertProducto = {
			tipoEntrada: {
				...productoTiendaValue.tipoEntrada,
				divisaId: productoTiendaValue.tipoEntrada.divisa
			},
			stock: productoTiendaValue.stock,
		};

		return payload;
	}

	valuedChanged() {
		this.editRegistroForm.valueChanges.subscribe((value) => {
			if (this.ProductoTienda != null) {
				console.log("value changes::", value);
				console.log("value grupo tarifa::", this.ProductoTienda);

				this.panelService.setDiscardChanges(false);

				const keysForm = Object.keys(value);

				const valuesDismatch = [];

				for (const keyForm of keysForm) {
					const valueForm = value[keyForm];
					const valueGrupoTarifa = this.ProductoTienda[keyForm];

					if (!isEqual(valueForm, valueGrupoTarifa)) {
						valuesDismatch.push(1);
					}
				}

				if (valuesDismatch.length === 0) {
					this.panelService.setDiscardChanges(true);
				}
			}
		});
	}

	discard() {

		const productoTiendaValue = this.generateProductoTiendaFormValue();

		this.changeValueForm(productoTiendaValue);
	}

	manageApi(PayloadProductoTienda: PayloadInsertProducto, mode: Mode) {
		this.loading = true;

		switch (mode) {
			case "I":
			case "D":
				//insert
				PayloadProductoTienda.tipoEntrada.pkId = "0";
				PayloadProductoTienda.stock.pkId = "0";
				this.InsertProductoTienda(PayloadProductoTienda);
				break;
			
			case "U":
				//update
				this.UpdateProductoTienda(PayloadProductoTienda);
				break;

			default:
				break;
		}
	}


	InsertProductoTienda(productoTienda: PayloadInsertProducto) {
		this.tiendaProductoService
			.insertProductoTienda(productoTienda)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(response) => {
					this.nextWindow(response, this.mode);
				},
				(error) => {
					const arrToGetTranslate = ["productosTienda", "insert"];
					this.httpErrorService.identificarErroresMicroservicios(error, arrToGetTranslate);
					this.save();
				}
			);
	}

	UpdateProductoTienda(productoTienda: PayloadInsertProducto) {
		this.tiendaProductoService
			.updateProductoTienda(productoTienda)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(response) => {
					this.nextWindow(response, this.mode);
				},
				(error) => {

					const arrToGetTranslate = ["productosTienda", "update"];

					this.httpErrorService.identificarErroresMicroservicios(error, arrToGetTranslate);
					this.save();
				}
			);
	}

	destroy() {
		this.panelService.setDiscardChanges(true);
		this.panelService.setClosePanel(null);
	}

	submit() {

		this.editRegistroForm.markAllAsTouched();


		const centrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
			this.centrosAsignados
		);

		this.editRegistroForm.get("centrosIds").setValue(centrosIds);

		if (this.editRegistroForm.valid) {

			const productoTienda = this.generatePayloadInsUpProductoTienda();

			this.manageApi(productoTienda, this.mode);
		}
	}

	getErrorMessage(field: string) {
		let fc_pv = this.editRegistroForm.get(field);
		if (fc_pv) {
			if (fc_pv.errors) {
				if (fc_pv.errors.required) {
					return this.translation["CampoRequerido"];
				} else if (field === "Empleado") {
					if (fc_pv.hasError("pattern")) {
						return this.translation["SoloNumeros"];
					}
				} else if (field === "Nombre") {
					if (fc_pv.hasError("pattern")) {
						return this.translation["SoloLetras"];
					}
				}
			}
		}

		return "Error";
	}

	getMessageMicroserviceError(code: string, insert: boolean = true) {
		const secondPart = insert ? "insert" : "update";
		const messageTranslate = code
			?
				this.translation['productosTienda'][secondPart][code]
					? this.translation['productosTienda'][secondPart][code]
					: this.translation['CodErr_-23']
			: this.translation['CodErr_-23'];

		return messageTranslate;
	}

	nextWindow(response: any, mode: Mode = "I") {
		this.save();
		this.destroy();
		if (response.mensajes.length > 0) {
			console.table(response.mensajes);
			response.mensajes.forEach((element) => {

				const isInsert = mode === "I" || mode === "D";
				const message = this.getMessageMicroserviceError(element.codigoMensaje, isInsert);

				this.snackvar_service.openSnackBar(
					message,
					"",
					"red-snackbar",
					"",
					4000
				);
			});
		} else {
			this.snackvar_service.openSnackBar(
				this.translation["MensajeSuccessSnackBar"],
				"",
				"green-snackbar"
			);
		}
	}

	save() {
		this.loading = false;
		this.panelService.setSavePanel(null);
	}

	validateFields(field: string) {
		return this.isValidAnyField(field);
	}

	isValidAnyField(field: string) {
		let fc_pv = this.editRegistroForm.controls;
		return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
	}
}
