import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { SuperClassComponent } from '@sharedV11/super-class-edit/super-class/super-class.component';
import { EditCodigoPromocionalBinesComponent } from './edit-codigo-promocional-bines/edit-codigo-promocional-bines.component';
import { ImportCodigoPromocionalBinesComponent } from './import-codigo-promocional-bines/import-codigo-promocional-bines.component';

@Component({
  selector: 'app-codigo-promocional-bines',
  templateUrl: './codigo-promocional-bines.component.html',
  styleUrls: ['./codigo-promocional-bines.component.scss']
})
export class CodigoPromocionalBinesComponent extends SuperClassComponent {

  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditCodigoPromocionalBinesComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }


  openImportPanel() {
    this.customanpanel = ImportCodigoPromocionalBinesComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }

}
