<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
	<app-buttons
		(save)="submit()"
		(close)="destroy()"
		(discard)="discard()"
		[buttonsWrap]="'panel'"
		[modificar]="modificar"
	></app-buttons>
	<div class="editor-registro">
		<div class="divForm">
			<form [formGroup]="importFileForm" (onSubmit)="submitImport()">
				<div class="form-group">
					<div class="col-12 col-md-4">
						<mat-form-field>
							<mat-label>{{ "ArchivoLabel" | translateV2 }}</mat-label>
							<button mat-icon-button matPrefix (click)="fileInput.click()">
								<mat-icon>attach_file</mat-icon>
							</button>
							<input type="text" matInput readonly formControlName="fileName" />
							<input
								matInput
								#fileInput
								type="file"
								hidden
								accept=".xlsx,.csv"
								(change)="onFileChange($event)"
							/>
						</mat-form-field>
					</div>

					<div class="col-12 col-md-4">
						<button
							mat-raised-button
							color="primary"
							[disabled]="!importFileForm.valid"
							(click)="submitImport()"
						>
							{{ translation.Importar }}
						</button>
					</div>

					<div class="col-12 col-md-4">
						<button
							mat-raised-button
							color="primary"
							(click)="downloadTemplate()"
						>
							{{ translation.DescargarPlantilla }}
						</button>
					</div>
				</div>
			</form>

			<ng-container *ngIf="codigosBinesImport.length > 0">
				<div style="margin: 20px 5px 0 5px; text-align: center">
					<mat-label>{{ translation.Centros }}</mat-label>

					<customan-dual-list
						[source]="centrosSinAsignar"
						[(destination)]="centrosAsignados"
						key="pkId"
						display="Nombre"
						[format]="format"
						[filter]="true"
					>
					</customan-dual-list>
				</div>
			</ng-container>

			<!--
			***
			*** TABLA DE CODIGOS PROMOCIONALES BINES
			***
			-->
			<ng-container *ngIf="codigosBinesImport.length > 0 && dataLoaded">
				<div class="text-center m-4">
					<mat-label>
						{{ "CodigosPromocionalesBines" | translateV2 }}
					</mat-label>
				</div>
				<div class="mat-elevation-z8 overflow-table col-12">
					<form [formGroup]="editRowsForm">
						<table
							mat-table
							[dataSource]="codigosBinesImport"
							class="mat-elevation-z8"
							formArrayName="rows"
						>
							<ng-container matColumnDef="Banco">
								<th mat-header-cell *matHeaderCellDef>
									{{ "Banco" | translateV2 }}
								</th>
								<td mat-cell *matCellDef="let row; let i = index">
									<div
										[formGroupName]="i"
									>
										<input
											matInput
											type="text"
											formControlName="Nombre"
											placeholder="{{ 'Nombre' | translateV2 }}"
										/>
										<mat-error matInput *ngIf="validateFields('Nombre', i)">
											{{ getErrorMessage("Nombre", i) }}
										</mat-error>
									</div>
								</td>
							</ng-container>
	
							<!-- Name Column -->
							<ng-container matColumnDef="Bin">
								<th mat-header-cell *matHeaderCellDef>
									{{ "Bin" | translateV2 }}
								</th>
								<td mat-cell *matCellDef="let row; let i = index">
									<div
										[formGroupName]="i"
									>
										<input
											matInput
											type="text"
											[formGroupName]="i"
											formControlName="NumeroTarjeta"
											placeholder="{{ 'NumeroTarjeta' | translateV2 }}"
										/>
										<mat-error matInput *ngIf="validateFields('NumeroTarjeta', i)">
											{{ getErrorMessage("NumeroTarjeta", i) }}
										</mat-error>
									</div>
								</td>
							</ng-container>
	
							<!-- Age Column -->
							<ng-container matColumnDef="Codigo">
								<th mat-header-cell *matHeaderCellDef>
									{{ "Codigo" | translateV2 }}
								</th>
								<td mat-cell *matCellDef="let row; let i = index">
									<div
										[formGroupName]="i"
									>
										<app-input-filter
											*ngIf="
												codigosPromocionalesList &&
												codigosPromocionalesList.length > 0
											"
											formControlText="CodigoPromocionalId"
											[disabled]="codigosPromocionalesList.length < 1"
											[label]="translation.CodigoPromocional"
											[placeHolderTranslation]="translation.CodigoPromocional"
											[list]="codigosPromocionalesList"
											textShow="Codigo"
											dataInput="Codigo"
											required="true"
											[data]="''"
											(close)="closeInputFilterCodigoPromocional($event, i)"
											(getMethods)="callbacksOnSelectCodigoPromocional($event)"
										></app-input-filter>
									</div>
								</td>
							</ng-container>
	
							<!-- Table Headers -->
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	
							<!-- Table Rows -->
							<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
						</table>
					</form>
				</div>
			</ng-container>
		</div>
	</div>
</div>
