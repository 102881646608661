<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>
<div class="editor-registro">
  <div class="divForm">
    <form  [formGroup]="editGrupoEntrada" (onSubmit)="submit()">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'Nombre' | translateV2:'CAPITALIZE' }}
          </mat-label>
          <input matInput formControlName="NombreGrupo">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'Descripcion' | translateV2:'CAPITALIZE' }}
          </mat-label>
          <input matInput formControlName="Descripcion">
        </mat-form-field>
      </div>
      <div class="col-md-6 col-sm-12">
        <mat-label>{{translation.ConMotivo}}</mat-label>
        <mat-slide-toggle matInput formControlName="ConMotivo" class="editor-campo-registro"></mat-slide-toggle>
        
      </div>

      <div style="margin: 20px 5px 0 5px; text-align: center;">
        <mat-label>{{translation.Centros}}</mat-label>
  
        <customan-dual-list [source]="centrosSinAsignar"
        [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
        </customan-dual-list>
      </div>
      
    </form>
  </div>
</div>
</div>