import { Translator } from "@sharedV11/services/translator/translator.service";

export function getLocale(translateService: Translator): string {
  const locale = translateService.getLocale();
  let localeReturn = "es";
  switch (locale) {
    case "es-ES":
    case "es":
      localeReturn = "es";
      break;
    
    case "ca-ES":
    case "ca":
      localeReturn = "ca";
      break;
    case "en-GB":
    case "en-US":
    case "en":
      localeReturn = "en";
      break;
    case "pt-BR":
      localeReturn = "pt";
      break;
    case "fr-FR":
    case "fr":
      localeReturn = "fr";
      break;
    case "de-DE":
      localeReturn = "de";
      break;
  }

  return localeReturn;
}