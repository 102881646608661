export const environment = {
  production: false,
  console: true,
  API_URL: "https://apides.apiacpos.com/api_bridge/api/",  
  CODIGO_APLICACION: "CUSTOMAN",
  ID_APLICACION: "19",
  chClaveEmpresa: "MUSEOCR",
  TICKETS_URL: "https://tickets.museocr.iacposdes.com/",
  chProyectoOnLine: "VENTAONLINE",
  chEntornoConexion: "DESARROLLO",
  chNombreProyecto: "CUSTOMMAN",
  chOrigenConexion: "CUSTOMMAN",
  NUMSERIE: "CUSTO$A$SZ45$A$MMAN$A$23g!.",
  API_MICROSERVICIOS: "https://apides.apiacpos.com/WebAPICoreBridge",
  VALIDATE_PERMISSIONS: false,
};
