import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditCategoriasJerarquias } from "./edit-categorias-jerarquias/edit-categorias-jerarquias";

@Component({
  selector: "app-categorias",
  templateUrl: "./categorias-jerarquias.component.html",
  styleUrls: ["./categorias-jerarquias.component.scss"],
})
export class CategoriasJerarquiasComponent extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }
  openPanel() {
    this.customanpanel = EditCategoriasJerarquias;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
