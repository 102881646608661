import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpParams,
	HttpRequest,
	HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, throwError } from "rxjs";

import { catchError, map, switchMap } from "rxjs/operators";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { MicroserviceService } from "@sharedV11/services/microservices/microservice.service";
import { MicroserviceTokenResponse } from "@sharedV11/interfaces/microservice/microservice.interface";
import { AuthenticationService } from "@sharedV11/services/authentication/authentication.service";

@Injectable()
export class RequestMicroserviceInterceptor implements HttpInterceptor {
	routesToIgnore = [];
	isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<MicroserviceTokenResponse | null> = new BehaviorSubject<MicroserviceTokenResponse | null>(null);

	constructor(
		private tokenService: ApiTokenService,
		private microservice: MicroserviceService,
		private authService: AuthenticationService,
	) {
		const apiURL = this.tokenService.getConfig("API_MICROSERVICIOS");
		this.routesToIgnore = [`${apiURL}/Token`, `${apiURL}/Refresh`];
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const apiURL = this.tokenService.getConfig("API_MICROSERVICIOS");
		if (req.url.includes(apiURL)) {
			if (this.routesToIgnore.includes(req.url)) {

				// Agregar cabeceras comunes
				
				const modReq = this.addHeadersData(req);

				return next.handle(modReq);
			}
			// Obtenemos el token
			// Importante: modificamos de forma inmutable, haciendo el clonado de la petición
			const modReq = this.addCommonData(req);
			return next.handle(modReq).pipe(
				catchError((error: HttpErrorResponse) => {
					if (error.status === 401) {
						return this.handle401Error(req, next);
					}
					return throwError(error);
				})
			);
		} else {
			return next.handle(req);
		}
	}

	addHeadersData(req: HttpRequest<any>): HttpRequest<any> {
		const claveEmpresa = this.tokenService.getConfig("chClaveEmpresa") || "";
		
		if (claveEmpresa === 'TICKAMORE_UNITARIO') {
			const modReq = req.clone({
				setHeaders: {
					"Content-Type": "application/json",
					"Cache-Control": "no-cache"
				},
			});
			return modReq;
		}

		const modReq = req.clone({
			setHeaders: {
				"Content-Type": "application/json",
				"Cache-Control": "no-cache",
				"IAC-Connection-String": claveEmpresa,
			},
		});

		return modReq;
	}

	addCommonData(req: HttpRequest<any>): HttpRequest<any> {
		const conexionId = localStorage.getItem("conexionIACPOS") || "";
		const token = localStorage.getItem("tokenMicroservice") || "";
		const claveEmpresa = this.tokenService.getConfig("chClaveEmpresa") || "";

		if (req.method === "GET" || req.method === "DELETE") {


			let keysParams = req.params.keys();
			console.log({
				keysParams,
			});

			let newParams = new HttpParams();

			if (keysParams.length > 0) {
				keysParams.forEach((key) => {
					newParams = newParams.set(key, req.params.get(key));
				});
			}

			newParams = newParams.set("ConexionId", conexionId);

			console.log({
				newParams,
			})
			let modReq;

			if (claveEmpresa === 'TICKAMORE_UNITARIO') {
				modReq = req.clone({
					setHeaders: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					params: newParams,
				});
			} else {
				modReq = req.clone({
					setHeaders: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
						'IAC-Connection-String': claveEmpresa,
					},
					params: newParams,
				});
			}

			return modReq;
		}

		if (req.body) {
			let body = req.body;

			body["ConexionId"] = conexionId;

			let modReq;

			if (claveEmpresa === 'TICKAMORE_UNITARIO') {
				modReq = req.clone({
					setHeaders: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: body,
				});
			} else {
				modReq = req.clone({
					setHeaders: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
						'IAC-Connection-String': claveEmpresa,
					},
					body: body,
				});
			}

			return modReq;
		}

		return req;
	}

	private handle401Error(req: HttpRequest<any>, next: HttpHandler) {

		console.log({
			isRefreshing: this.isRefreshing,
		})

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.microservice.getTokenMicroservice("", "").pipe(
        switchMap((token: MicroserviceTokenResponse) => {
					this.isRefreshing = false;
					this.microservice.loginMicroservice(token);
          this.refreshTokenSubject.next(token);
          return next.handle(this.addCommonData(req));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.logout();
          return throwError(() => error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        switchMap((token) => {
					console.log({
						action: 'switchMap',
						token,
					})
          if (token) {
            return next.handle(this.addCommonData(req));
          }
          return throwError(() => new Error('No token available'));
        })
      );
    }
  }
}
