<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
	<app-buttons
		(save)="submit()"
		(close)="destroy()"
		(discard)="discard()"
		[buttonsWrap]="'panel'"
		[modificar]="modificar"
	></app-buttons>
	<div class="editor-registro">
		<div class="divForm">
			<form [formGroup]="editRegistroForm" (onSubmit)="submit()">
				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.Nombre }}</mat-label>
						<input matInput formControlName="nombre" />
						<mat-error *ngIf="validateFields('nombre')">
							{{ getErrorMessage("nombre") }}
						</mat-error>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.Descripcion }}</mat-label>
						<input matInput formControlName="descripcion" />
						<mat-error *ngIf="validateFields('descripcion')">
							{{ getErrorMessage("descripcion") }}
						</mat-error>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>{{ "CODIGOBAR" | translateV2 }}</mat-label>
						<input matInput formControlName="barCode" />
						<mat-error *ngIf="validateFields('barCode')">
							{{ getErrorMessage("barCode") }}
						</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>{{ "TextoEnPantalla" | translateV2 }}</mat-label>
						<input matInput formControlName="textoPantalla" />
						<mat-error *ngIf="validateFields('textoPantalla')">
							{{ getErrorMessage("textoPantalla") }}
						</mat-error>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>{{ "TextoImpresion" | translateV2 }}</mat-label>
						<input matInput formControlName="textoImpresion" />
						<mat-error *ngIf="validateFields('textoImpresion')">
							{{ getErrorMessage("textoImpresion") }}
						</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>{{ "TextoEnDisplay" | translateV2 }}</mat-label>
						<input matInput formControlName="textoDisplay" />
						<mat-error *ngIf="validateFields('textoDisplay')">
							{{ getErrorMessage("textoDisplay") }}
						</mat-error>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="gruposTipoEntrada && gruposTipoEntrada.length > 0"
					>
						<mat-label>{{ "Grupo" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'Grupo' | translateV2 }}"
							formControlName="grupoId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let grupo of gruposTipoEntrada"
								[value]="+grupo.pkId"
							>
								{{ grupo.NombreGrupo }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="proveedoresTienda && proveedoresTienda.length > 0"
					>
						<mat-label>{{ "Proveedor" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'Proveedor' | translateV2 }}"
							formControlName="proveedorId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let proveedor of proveedoresTienda"
								[value]="+proveedor.pkId"
							>
								{{ proveedor.nombre }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="categoriasJerarquicas && categoriasJerarquicas.length > 0"
					>
						<mat-label>{{ "Categoria" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'Categoria' | translateV2 }}"
							formControlName="categoriaJerarquicaId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let categoria of categoriasJerarquicas"
								[value]="+categoria.pkId"
							>
								{{ categoria.nombre }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="tiendaBotonera && tiendaBotonera.length > 0"
					>
						<mat-label>{{ "TeclaRapida" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'TeclaRapida' | translateV2 }}"
							formControlName="teclaRapida"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let teclaRapida of tiendaBotonera"
								[value]="+teclaRapida.teclaRapida"
							>
								{{ teclaRapida.teclaRapida }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="tiposEntrada && tiposEntrada.length > 0"
					>
						<mat-label>{{ "DatosNominativos" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'DatosNominativos' | translateV2 }}"
							formControlName="teDatosId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let tipoEntrada of tiposEntrada"
								[value]="+tipoEntrada.pkId"
							>
								{{ tipoEntrada.Nombre }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="tiposProductos && tiposProductos.length > 0"
					>
						<mat-label>{{ "TipoProducto" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'TipoProducto' | translateV2 }}"
							formControlName="tipoProductoId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let tipoProducto of tiposProductos"
								[value]="+tipoProducto.pkId"
							>
								{{ tipoProducto.Codigo }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="gruposAsociados && gruposAsociados.length > 0"
					>
						<mat-label>{{ "GrupoAsociado" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'GrupoAsociado' | translateV2 }}"
							formControlName="grupoAsociadoId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let grupoAsociado of gruposAsociados"
								[value]="+grupoAsociado.pkId"
							>
								{{ grupoAsociado.Codigo }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="tiposVisita && tiposVisita.length > 0"
					>
						<mat-label>{{ "TipoVisita" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'TipoVisita' | translateV2 }}"
							formControlName="tipoVisitaId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let tipoVisita of tiposVisita"
								[value]="+tipoVisita.pkId"
							>
								{{ tipoVisita.Codigo }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="tiposNiveles && tiposNiveles.length > 0"
					>
						<mat-label>{{ "TipoNivel" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'TipoNivel' | translateV2 }}"
							formControlName="tipoNivelId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let tipoNivel of tiposNiveles"
								[value]="+tipoNivel.pkId"
							>
								{{ tipoNivel.Nombre }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="tiposPublico && tiposPublico.length > 0"
					>
						<mat-label>{{ "iTipoPublicoId" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'iTipoPublicoId' | translateV2 }}"
							formControlName="tipoPublicoId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let tipoPublico of tiposPublico"
								[value]="+tipoPublico.pkId"
							>
								{{ tipoPublico.Descripcion }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="tiposVisitante && tiposVisitante.length > 0"
					>
						<mat-label>{{ "iTipoVisitanteId" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'iTipoVisitanteId' | translateV2 }}"
							formControlName="tipoVisitanteId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option
								*ngFor="let tipoVisitante of tiposVisitante"
								[value]="+tipoVisitante.pkId"
							>
								{{ tipoVisitante.Descripcion }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field
						class="input-select"
						appearance="outline"
						*ngIf="niveles && niveles.length > 0"
					>
						<mat-label>{{ "iNivelesId" | translateV2 }}</mat-label>
						<mat-select
							placeholder="{{ 'iNivelesId' | translateV2 }}"
							formControlName="nivelesId"
						>
							<mat-option value=""> --- </mat-option>
							<mat-option *ngFor="let nivel of niveles" [value]="+nivel.pkId">
								{{ nivel.Descripcion }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group fg-toggles" *ngIf="showTogglesRestauracion">
					<mat-slide-toggle color="primary" formControlName="codigoBarras">
						{{ "CodigoDeBarras" | translateV2 }}
					</mat-slide-toggle>

					<mat-slide-toggle color="primary" formControlName="imprimirTicket">
						{{ "ImprimirTicket" | translateV2 }}
					</mat-slide-toggle>

					<mat-slide-toggle color="primary" formControlName="imprimirPrecio">
						{{ "ImprimirPrecio" | translateV2 }}
					</mat-slide-toggle>
				</div>

				<div class="form-group fg-toggles">
					<mat-slide-toggle color="primary" formControlName="impresionConjunta" *ngIf="showTogglesRestauracion">
						{{ "bImpresionConjunta" | translateV2 }}
					</mat-slide-toggle>

					<mat-slide-toggle color="primary" formControlName="tieneNominacion">
						{{ "TieneNominacion" | translateV2 }}
					</mat-slide-toggle>

					<mat-slide-toggle color="primary" formControlName="aLaVenta">
						{{ "ALaVenta" | translateV2 }}
					</mat-slide-toggle>
				</div>

				<div style="margin: 20px 5px 0 5px; text-align: center">
					<mat-label>{{ "Centros" | translateV2 }}</mat-label>

					<customan-dual-list
						[source]="centrosSinAsignar"
						[(destination)]="centrosAsignados"
						key="pkId"
						display="Nombre"
						[format]="format"
						[filter]="true"
					>
					</customan-dual-list>
				</div>
			</form>
		</div>
	</div>
</div>
