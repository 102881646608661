import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { userResponse } from "../../classes/userResponse/user-response";
import { HttpErrorsService } from "../alerts/errors/http-errors.service";
import { ConectarApiService } from "../api/connection/conectar-api.service";

@Injectable({
	providedIn: "root",
})
export class AuthenticationService {
	constructor(
		private servicioAPI: ConectarApiService,
		private httpErrorService: HttpErrorsService,
		private router: Router
	) {}
	login(usuario: userResponse) {
		sessionStorage.setItem("currentUser", JSON.stringify(usuario));
	}

	logout() {
		// quitar usuario del sessionStorage para desloguearse

		this.servicioAPI.logOut().subscribe((o) => {
			if (o.Mensajes.length == 0) {
				sessionStorage.removeItem("currentUser");
				localStorage.removeItem("tokenMicroservice");
				localStorage.removeItem("microserviceData");
				this.router.navigate(["/login"]);
			}
		});
	}
	verificar(): boolean {
		if (sessionStorage.getItem("currentUser") != null) {
			return true;
		} else {
			return false;
		}
	}

	ocultarMenusQueryString(_ruta: ActivatedRoute): boolean {
		this.verificarAccesoQueryString(_ruta);
		if (
			_ruta.snapshot.queryParamMap.get("SM") !== null &&
			_ruta.snapshot.queryParamMap.get("SM") === "1"
		) {
			sessionStorage.setItem("OcultarMenu", "true");
			return true;
		} else {
			if (
				sessionStorage.getItem("OcultarMenu") != null &&
				sessionStorage.getItem("OcultarMenu") == "true"
			) {
				if (
					_ruta.snapshot.queryParamMap.get("SM") !== null &&
					_ruta.snapshot.queryParamMap.get("SM") !== "1"
				) {
					sessionStorage.setItem("OcultarMenu", "false");
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		}
	}

	//Parametros aceptados en la ruta:
	//  SM (=1) Sin Menu Lateral (ocultarMenu)
	//  US Usuario
	//  PW Paswword (encriptada TODO)
	async verificarAccesoQueryString(_ruta: ActivatedRoute) {
		if (_ruta !== null) {
			let _usuario = _ruta.snapshot.queryParamMap.get("US");
			let _pwd = _ruta.snapshot.queryParamMap.get("PW");
			let _usuarioO: userResponse;
			if (_usuario !== null && _pwd !== null) {
				// COMPROBAR QUE EL USUARIO SEA DIFERENTE del de memoria o el de memoria sea null,
				if (sessionStorage.getItem("currentUser") == null) {
					//esta llamada ha de ser asincrona, esperamos a que acabe por eso la pasamos a una funcion async
					await this.verificarAccesologinAPI(_usuario, _pwd);
				} else {
					_usuarioO = new userResponse().deserialize(
						JSON.parse(sessionStorage.getItem("currentUser"))
					);
					if (_usuarioO.DatosResult.NombreUsuario.trim() != _usuario.trim()) {
						//esta llamada ha de ser asincrona, esperamos a que acabe por eso la pasamos a una funcion async
						await this.verificarAccesologinAPI(_usuario, _pwd);
					}
				}
			}
		}
	}

	async verificarAccesologinAPI(usuario: string, pwd: string) {
		await this.servicioAPI.getUsuarioAsync(usuario, pwd).then(
			(data) => {
				let usuarioenSession = new userResponse().deserialize(data);
				if (
					usuarioenSession.DatosResult != null &&
					usuarioenSession.DatosResult.DeBaja.toLowerCase() == "false"
				) {
					this.login(usuarioenSession);
				} else {
					//this.alertaServicio.error('No se ha iniciado sesión correctamente o tu cuenta está deshabilitada temporalmente.');
				}
			},
			(error) => {
				//this.alertaServicio.error(error);
				this.httpErrorService.identificarErrores(error);
			}
		);
	}

	getPermisosComoBotones(): Array<any> {
		if (sessionStorage.getItem("currentUser") != null) {
			var permisos = new userResponse().deserialize(
				JSON.parse(sessionStorage.getItem("currentUser"))
			).DatosResult.Permisos;
			if (permisos.length > 0) {
				// Metemos los permisos en hijos y padres seleccionados por VER
				var permisosbotonespadre = permisos.filter(
					(x) =>
						x.NombreAccion.trim().toUpperCase() == "VER" && x.ModuloIdPadre == 0
				);
				var permisosbotoneshijos = permisos.filter(
					(x) =>
						x.NombreAccion.trim().toUpperCase() == "VER" && x.ModuloIdPadre != 0
				);
				return permisosbotonespadre.map(function (x) {

          const permisosbotoneshijosOrdenado = permisosbotoneshijos
            .filter((y) => y.ModuloIdPadre == x.ModuloId)
            .map(function (z) {
              return {
                id: z.AccionId.toString(),
                texto: z.NombreModuloIdioma,
                icono: z.IconModulo,
                route: z.Ventana,
              };
            })
            .sort((a, b) => {
              if (a.id > b.id) {
                return 1;
              }
              if (a.id < b.id) {
                return -1;
              }
              return 0;
            });

					return {
						id: x.AccionId.toString(),
						texto: x.NombreModuloIdioma,
						icono: x.IconModulo,
						/* submenu: permisosbotoneshijos
							.filter((y) => y.ModuloIdPadre == x.ModuloId)
							.map(function (z) {
								return {
									id: z.AccionId.toString(),
									texto: z.NombreModuloIdioma,
									icono: z.IconModulo,
									route: z.Ventana,
								};
							}), */
            submenu: permisosbotoneshijosOrdenado,
					};
				});
			} else {
				return [];
			}
		} else {
			return [];
		}
	}

	visualizacionAccion(ruta: string, accion: string): boolean {
		if (sessionStorage.getItem("currentUser") != null) {
			var permisos = new userResponse().deserialize(
				JSON.parse(sessionStorage.getItem("currentUser"))
			).DatosResult.Permisos;
			if (permisos.length > 0) {
				var permisosboton = permisos.filter(
					(x) =>
						x.NombreAccion.trim().toUpperCase() == accion && x.Ventana == ruta
				);
				if (permisosboton.length > 0) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}
