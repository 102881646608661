<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading}">
  <app-buttons *ngIf="!loading" (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar">
  </app-buttons>

  <form [formGroup]="editorRegistroForm" id="formulario">
    <div class="divForm">

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Codigo}}</mat-label>
            <input matInput formControlName="chCodigo" [ngClass]="{ 'is-invalid': f.chCodigo.errors }"  [readonly]="mode=='U'">
            <mat-error *ngIf="f.chCodigo.errors">
              <span *ngIf="f.chCodigo.errors?.required">{{translation.Codigo}}</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.Nombre}}</mat-label>
          <input matInput formControlName="chNombrePromo" (change)="cambiaNombre($event)"
            [ngClass]="{ 'is-invalid': f.chNombrePromo.errors }">
          <mat-error *ngIf="f.chNombrePromo.errors">
            <span *ngIf="f.chNombrePromo.errors.required">{{translation.SeRequiereNombre}}</span>
          </mat-error>
        </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.Descripcion}}</mat-label>
          <input matInput formControlName="chDescripcionNombre"
            [ngClass]="{ 'is-invalid': f.chDescripcionNombre.errors }">
        </mat-form-field>
      </div>
        <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.TextoEnPantalla}}</mat-label>
          <input matInput formControlName="chTextoPantalla" [ngClass]="{ 'is-invalid': f.chTextoPantalla.errors }">
        </mat-form-field>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.TextoImpresion}}</mat-label>
          <input matInput formControlName="chTextoImpresion" [ngClass]="{ 'is-invalid': f.chTextoImpresion.errors }">
        </mat-form-field>
      </div>
        <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.TextoEnDisplay}}</mat-label>
          <input matInput formControlName="chTextoDisplay" [ngClass]="{ 'is-invalid': f.chTextoDisplay.errors }">
        </mat-form-field>
      </div>
      </div>

      <!-- <div class="form-group row" >
        <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.chTipoValidez}}</mat-label>
          <mat-select required placeholder="{{translation.chTipoValidez}}"
            (selectionChange)="iValidezaCero($event,editorRegistroForm)" formControlName="chTipoValidez"
            name="chTipoValidez">
            <mat-option value="D">{{translation.Dias}}</mat-option>
            <mat-option value="M">{{translation.Meses}}</mat-option>
            <mat-option value="S">{{translation.SinCaducidad}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.chTipoValidez.hasError('required')">{{translation.CampoRequerido}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="f.chTipoValidez.value=='D' || f.chTipoValidez.value=='M'">
          <mat-label>{{translation.iValidez}}</mat-label>
          <input matInput type="number" formControlName="iValidez" min="0" class="editor-campo-registro"
            [ngClass]="{ 'is-invalid': f.iValidez.errors }">
          <mat-error *ngIf="f.iValidez.errors?.required">{{translation.CampoRequerido}}</mat-error>
        </mat-form-field>

        </div>


      </div> -->

      <div class="fullForm row">

        <div class="col-sm-12 col-md-6" >
          <div [ngClass]="{ 'is-invalid': iGrupoId.invalid }">
            <app-input-filter *ngIf="bonoType && gruposTipoBono" formControlText="iGrupoId" [label]="translation.iGrupoId" [disabled]="gruposTipoBono.length<1"
              [placeHolderTranslation]="translation.grupo" [list]="gruposTipoBono" textShow="NombreGrupo"
              dataInput="pkId" [data]="bonoType.iGrupoId" required="false" (close)="closeInputFilter($event)">
            </app-input-filter>
          </div>
          <!-- <mat-form-field appearance="outline">
            <mat-label>{{translation.selecciona}} {{translation.iGrupoId}}</mat-label>
            <mat-select required placeholder="{{translation.chTipoValidez}}" formControlName="iGrupoId"
            name="iGrupoId">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let element of gruposTipoBono" [value]="element.pkId">
              {{element.NombreGrupo}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.iGrupoId.hasError('required')">{{translation.CampoRequerido}}</mat-error>
          </mat-form-field> -->
        </div>

        <div class="col-sm-12 col-md-6">
          <app-input-filter *ngIf="bonoType && gruposTiposProductos" formControlText="GrupoMotivoId" [label]="translation.GrupoMotivo" [disabled]="GrupoMotivosId && GrupoMotivosId.length<1"
            [placeHolderTranslation]="translation.GrupoMotivo" [list]="GrupoMotivosId" textShow="NombreGrupo"
            dataInput="pkId" [data]="bonoType['GrupoMotivoId']" required="false" (close)="closeInputFilter($event)">
          </app-input-filter>
        </div>
      </div>
      <div class="fullForm row">
        <div class="col-12">
        <!-- <app-input-filter *ngIf="bonoType && gruposTiposProductos" formControlText="iTipoProductoId" [label]="translation.iTipoProductoId"
          [placeHolderTranslation]="translation.iTipoProductoId" [list]="gruposTiposProductos" textShow="Descripcion"
          dataInput="pkId" [data]="bonoType.iTipoProductoId" required="false" (close)="closeInputFilter($event)">
        </app-input-filter> -->
        <app-input-filter *ngIf="bonoType && gruposTiposProductos" formControlText="iTipoProductoId" [label]="translation.iTipoProductoId"  [disabled]="gruposTiposProductos.length<1"
          [placeHolderTranslation]="translation.iTipoProductoId" [list]="gruposTiposProductos" textShow="Descripcion"
          dataInput="pkId" [data]="bonoType.iTipoProductoId" required="false" (close)="closeInputFilter($event)">
        </app-input-filter>
        <!--   <mat-form-field appearance="outline">
        <mat-label>{{translation.selecciona}} {{translation.iTipoProductoId}}</mat-label>
        <mat-select required placeholder="{{translation.iTipoProductoId}}" formControlName="iTipoProductoId"
        name="iTipoProductoId">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let element of gruposTiposProductos" [value]="element.pkId">
          {{element.Descripcion}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.iTipoProductoId.hasError('required')">{{translation.CampoRequerido}}</mat-error>
      </mat-form-field>  -->
        </div>
      </div>

      <div class="fullForm row">
        <div class="col-12">
     
         <app-input-filter *ngIf="bonoType && gruposAsociados" formControlText="iGrupoAsociadoId" [label]="translation.iGrupoAsociadoId"  [disabled]="gruposAsociados.length<1"
          [placeHolderTranslation]="translation.iGrupoAsociadoId" [list]="gruposAsociados" textShow="Descripcion"
          dataInput="pkId" [data]="bonoType.iGrupoAsociadoId" required="false" (close)="closeInputFilter($event)" (selected)="isnovalid()">
        </app-input-filter>
        <!-- <mat-form-field appearance="outline">
        <mat-label>{{translation.selecciona}} {{translation.iGrupoAsociadoId}}</mat-label>
        <mat-select placeholder="{{translation.iGrupoAsociadoId}}" formControlName="iGrupoAsociadoId"
        name="iGrupoAsociadoId" >
        <mat-option>--</mat-option>
        <mat-option *ngFor="let element of gruposAsociados" [value]="element.pkId">
          {{element.Descripcion}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.iGrupoAsociadoId.hasError('required')">{{translation.CampoRequerido}}</mat-error>
      </mat-form-field>  -->
        </div>
      </div>

      <div class="fullForm row">
        <!--mat-form-field appearance="outline">
        <mat-label>{{translation.selecciona}} {{translation.iTipoVisitaId}}</mat-label>
        <mat-select placeholder="{{translation.iTipoVisitaId}}" formControlName="iTipoVisitaId"
        name="iTipoVisitaId" >
        <mat-option>--</mat-option>
        <mat-option *ngFor="let element of tiposvisitas" [value]="element.pkId">
          {{element.Descripcion}}
        </mat-option>
      </mat-select>




      <mat-error *ngIf="f.iTipoVisitaId.hasError('required')">{{translation.CampoRequerido}}</mat-error>
      </mat-form-field-->
      <div class="col-12">
        <app-input-filter *ngIf="bonoType && tiposvisitas" formControlText="iTipoVisitaId" [label]="translation.iTipoVisitaId" [disabled]="tiposvisitas.length<1"
          [placeHolderTranslation]="translation.iTipoVisitaId" [list]="tiposvisitas" textShow="Descripcion"
          dataInput="pkId" [data]="bonoType.iTipoVisitaId" (close)="closeInputFilter($event)"></app-input-filter>

      </div>

      </div>




      <div class="fullForm row">
        <div class="col-12">
        <app-input-filter *ngIf="bonoType && tiposnivel" formControlText="iTipoNivelId" [label]="translation.iTipoNivelId" [disabled]="tiposnivel.length<1"
          [placeHolderTranslation]="translation.iTipoNivelId" [list]="tiposnivel" textShow="Nombre" dataInput="pkId"
          [data]="bonoType.iTipoNivelId" required="false" (close)="closeInputFilter($event)"></app-input-filter>
        </div>
      </div>


      <div class="fullForm row">
        <div class="col-12">
          <app-input-filter *ngIf="bonoType && categoriaDatos" formControlText="iCategoriaId" [label]="translation.iCategoriaId" [disabled]="categoriaDatos.length<1"
            [placeHolderTranslation]="translation.iCategoriaId" [list]="categoriaDatos" textShow="chNombre"
            dataInput="pkId" [data]="bonoType.iCategoriaId" required="false" (close)="closeInputFilter($event)">
          </app-input-filter>
        </div>
        <!--  <mat-form-field appearance="outline">
        <mat-label>{{translation.selecciona}} {{translation.iCategoriaId}}</mat-label>
        <mat-select placeholder="{{translation.iCategoriaId}}" formControlName="iCategoriaId"
        name="iCategoriaId" >
        <mat-option>--</mat-option>
        <mat-option *ngFor="let element of categoriaDatos" [value]="element.pkId">
          {{element.chNombre}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.iCategoriaId.hasError('required')">{{translation.CampoRequerido}}</mat-error>
      </mat-form-field>  -->
      </div>

      <div class="fullForm row">
        
        <div class="col-6">
        <app-input-filter *ngIf="bonoType && tiposcontrol" formControlText="siCodigoTipoControl" [label]="translation.siCodigoTipoControl" [disabled]="tiposcontrol.length<1"
          [placeHolderTranslation]="translation.siCodigoTipoControl" [list]="tiposcontrol" textShow="Nombre" dataInput="pkId"
          [data]="bonoType.siCodigoTipoControl" required="false" (close)="closeInputFilter($event)"></app-input-filter>
        </div>
        
        <div class="col-6">
<!--           <mat-form-field appearance="outline">
            <mat-label>{{translation.iNominar}}</mat-label>
            <input matInput formControlName="iNominar" [ngClass]="{ 'is-invalid': f.iNominar.errors }">
          </mat-form-field> -->

          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.iNominar}}</mat-label>
            <mat-select placeholder="{{translation.iNominar}}" formControlName="iNominar">
              <mat-option value="0">
                {{translation.sinnominar}}
              </mat-option>
              <mat-option value="1">
                {{translation.nominarpromo}}
              </mat-option>
              <mat-option value="2">
                {{translation.nominarentradas}}
              </mat-option>
            </mat-select>
<!--             <mat-error *ngIf="validateFields('TipoDeVenta')">
              {{getErrorMessage("TipoDeVenta")}}
            </mat-error> -->
          </mat-form-field>

        </div>
      </div>



      <div class="fullForm row">
        <div class="col-12">
        <app-input-filter *ngIf="bonoType && TEDatos" formControlText="iTEDatosId" [label]="translation.iTEDatosId" [disabled]="TEDatos.length<1"
          [placeHolderTranslation]="translation.iTEDatosId" [list]="TEDatos" textShow="Nombre" dataInput="pkId"
          [data]="bonoType.iTEDatosId" required="false" (close)="closeInputFilter($event)"></app-input-filter>
        <!--   <mat-form-field appearance="outline">
        <mat-label>{{translation.selecciona}} {{translation.iTEDatosId}}</mat-label>
        <mat-select  placeholder="{{translation.iTEDatosId}}" formControlName="iTEDatosId"
        name="iTEDatosId" >
        <mat-option>--</mat-option>
        <mat-option *ngFor="let element of TEDatos" [value]="element.pkId">
          {{element.Nombre}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.iTEDatosId.hasError('required')">{{translation.iTEDatosId}}</mat-error>
      </mat-form-field>  -->
        </div>
      </div>

      <div class="fullForm row">
        <div class="matslide">
          <mat-label>{{translation.bALaVenta}} <mat-slide-toggle formControlName="bALaVenta"></mat-slide-toggle>
          </mat-label>
          <mat-label>{{translation.bControlarAcceso}} <mat-slide-toggle formControlName="bControlarAcceso">
          </mat-slide-toggle>
          </mat-label>

        </div>


        <div class="matslide">
          <mat-label>{{translation.CodigoDeBarras}} <mat-slide-toggle formControlName="CodigoBarras"></mat-slide-toggle>
          </mat-label>
          <mat-label>{{translation.BarCodeInternet}} <mat-slide-toggle formControlName="BarCodeInternet">
            </mat-slide-toggle>
          </mat-label>
        </div>

        <div class="matslide">
          <mat-label>{{translation.bImprimirPromo}} <mat-slide-toggle formControlName="bImprimirPromo">
          </mat-slide-toggle>
          </mat-label>
          <mat-label>{{translation.bImpresionConjunta}} <mat-slide-toggle formControlName="bImpresionConjunta">
          </mat-slide-toggle>
          </mat-label>
        </div>
        
        <div class="matslide">
          <mat-label>{{translation.bImprimirPrecio}} <mat-slide-toggle formControlName="bImprimirPrecio">
            </mat-slide-toggle>
          </mat-label>
          <mat-label>{{translation.ImprimirTicket}} <mat-slide-toggle formControlName="bImprimirTicket">
            </mat-slide-toggle>
          </mat-label>
          <!--mat-label>{{translation.chTeclaRapida}} <mat-slide-toggle formControlName="chTeclaRapida"></mat-slide-toggle>
        </mat-label-->
        </div>
      </div>

      <div style="margin: 20px 5px 0 5px; text-align: center;">
        <mat-label>{{translation.Centros}}</mat-label>
  
        <customan-dual-list [source]="centrosSinAsignar"
        [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
        </customan-dual-list>
      </div>
      
      <!-- Botonera -->
      <div class="fullForm row">

        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.PosicionEnBotonera}}</mat-label>
          <mat-select placeholder="{{translation.TeclaRapidaEnBotoneraAsignacionActual}}"
            formControlName="chTeclaRapida" (selectionChange)="CambioTeclaRapida($event)">
            <mat-option>--</mat-option>
            <ng-container *ngFor="let element of tiposBotoneraEntrada">
              <!--mat-option *ngIf="element.NombreTipo != '' " >
                  {{element.TeclaRapida}} : {{element.NombreTipo}}
                </mat-option-->
              <mat-option [value]="element.TeclaRapida">
                {{element.TeclaRapida}} : {{element.NombreTipo}}
              </mat-option>
            </ng-container>

          </mat-select>
        </mat-form-field>

      </div>



      <app-table-interactive #ti_tipoBono  [tableName]="'tbTiposPromocionEntradas'"  [parent_pkId]="pkId" (guardatabla)="submit(false)"></app-table-interactive>


    </div>
  </form>
</div>